

import { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listMessages, listUsers, getUser } from '../../graphql/queries';
import { createMessages } from '../../graphql/mutations';

//bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { createWorkouts} from '../../graphql/mutations';
import {listWorkouts} from '../../graphql/queries';


function Data() {

    const [functionalfitnessfinished, setFunctionalFitnessFinished] = useState(false)
    const [militaryprepfinished, setMilitaryPrepFinished] = useState(false)

    async function fetchData(url, offset = null, recursionDepth = 0, result=[]) {
        let apiUrl = url;
        if (offset) {
          apiUrl += `?offset=${offset}&format=json-pretty`;
        } else {
          apiUrl += '/?format=json-pretty';
        }
    
        try {
          const response = await fetch(apiUrl);
          const data = await response.json();
    
          const items = data.items;
          result = result.concat(items);

          console.log(result)
    
          // Check if there are more pages and recursion depth is less than 3
          // limit this to only happen 3 times
          if (data.pagination && data.pagination.nextPage && recursionDepth < 1 && data.pagination.nextPageOffset < 3) {
            // Fetch data from the next page recursively, incrementing the recursion depth
            return fetchData(url, data.pagination.nextPageOffset, recursionDepth + 1, result);
          } else {
            // Return the result array when all recursive calls are complete
            return result;
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      
    const insertWorkouts = async (workoutsList, category) => {
    
          const monthAbbreviations = {
            JAN: '01',
            FEB: '02',
            MAR: '03',
            APR: '04',
            MAY: '05',
            JUN: '06',
            JULY: '07',
            AUG: '08',
            SEP: '09',
            OCT: '10',
            NOV: '11',
            DEC: '12'
          };
    
          function extractYearFromURL(url) {
            const yearRegex = /\/(\d{4})\//; // Match the pattern /YYYY/
          
            const match = url.match(yearRegex);
          
            if (match && match[1]) {
              return match[1];
            }
          
            return null; // Return null if the year is not found
          }
    
          function formatDate(dateString, man_year) {
    
            if (category === 'FUNCTIONALFITNESS') {
    
              const [month, day, year] = dateString.split('/');
          
              // Pad the month and day with leading zeros if needed
              const formattedMonth = month.padStart(2, '0');
              const formattedDay = day.padStart(2, '0');
            
              // Return the formatted date
              return `${formattedMonth}/${formattedDay}/${year}`;
              
            } else {
    
              // Extract the day and month from the input string
                const nonNumericIndex = dateString.search(/\D/);
                const day = dateString.slice(0, nonNumericIndex);
                const monthAbbreviation = dateString.slice(nonNumericIndex).toUpperCase();

                // Pad the day with a leading zero if necessary
                const formattedDay = day.padStart(2, '0');

                // Get the corresponding month number from the abbreviation
                const month = monthAbbreviations[monthAbbreviation];

                // Combine the day, month, and year to form the final date string
                const formattedDate = `${month}/${formattedDay}/${man_year}`;

                return formattedDate;
            }
            
          }
    
          function formatCode(code) {
            // Remove HTML tags and replace them with line breaks
            const formattedCode = code.replace(/<\/?[^>]+(>|$)/g, '\n');
          
            // Remove multiple consecutive line breaks
            const finalCode = formattedCode.replace(/\n{2,}/g, '\n');
          
            // Remove "Functional Fitness" and the line with multiple dashes
            const cleanedCode = finalCode.replace(/Functional Fitness[\n-]+.*\n/, '');
    
            console.log(cleanedCode)
          
            return cleanedCode.trim(); // Trim leading/trailing whitespace
          }

          const checkIfWorkoutExists = async (date, workoutType) => {
            try {
                const queryResult = await API.graphql(
                    graphqlOperation(listWorkouts, {
                      filter: {
                        date: { eq: date.toString() },
                        workout_type: { eq: workoutType.toString() },
                        _deleted: { ne: true }
                      }
                    })
                  );

                  const workouts = queryResult.data.listWorkouts.items;
                  
                  // Filter out deleted workouts
                  const filteredWorkouts = workouts.filter((workout) => !workout._deleted);

                  console.log('filtered Workouts: ' + JSON.stringify(filteredWorkouts.length))
                    
                  //console.log(date + " " + workoutType + " " + JSON.stringify(queryResult));
              
                  return filteredWorkouts.length > 0;
            } catch (error) {
              console.error('Error querying existing records:', error);
              return false;   
            }
          };
          
    
        //Clean the data
        const convertItems = (items) => {

          return items.map((item) => {

            return {
              date: formatDate(item.title, extractYearFromURL(item.fullUrl)),
              title: item.title,
              desc: formatCode(item.body),
              workout_type: category,
              legacy: true
            };

          });

        };
    
        const convertedItems = convertItems(workoutsList);
        //console.log(convertedItems);
        

        try {
            for (const item of convertedItems) {
              const { date, workout_type } = item;

              console.log(date + " " + workout_type)
        
              // Check if the workout already exists
              const workoutExists = await checkIfWorkoutExists(date, workout_type);
        
              if (workoutExists) {
                console.log(`Skipping insertion for workout: ${date} (${workout_type})`);
                continue;
              }

              console.log(item)
        
              // Insert the workout if it doesn't exist
              await API.graphql(graphqlOperation(createWorkouts, { input: item }));
              console.log(`Inserted workout: ${date} (${workout_type})`);
            }
        
            console.log('Batch insert successful');
          } catch (error) {
            console.error('Error inserting records:', error);
          }

      };


      const handleDataPullAPI = async () => {

        const queryResult = await API.graphql(
            graphqlOperation(listWorkouts)
          );
        
        console.log(queryResult)

        
      
        try {

          //Clear Cookies
          document.cookie.split(";").forEach(cookie => {
            document.cookie = cookie.replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
           });
          
          console.log('Starting Functional Fitness import')
          const functionalfitness = await fetchData('https://cronusfit.org/functionalfitness');
          console.log(JSON.stringify(functionalfitness, null, 2));
          console.log('Length: ' + functionalfitness.length)

          await insertWorkouts(functionalfitness, "FUNCTIONALFITNESS")

          setFunctionalFitnessFinished(true)
          
          console.log('Starting Military Prep import')
          const militaryprep = await fetchData('https://cronusfit.org/military-prep');
          await insertWorkouts(militaryprep, "MILITARYPREP")

          setMilitaryPrepFinished(true)
          
          
        } catch (error) {
          console.error('Error:', error);
        }
    
    
      }

    
    return (

        <Container fluid="xxl" style={{paddingTop: 15, paddingBottom: 15, height: '100vh', color: 'white'}}>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
                <Col>
                  <h3>Data Import</h3>
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                <Col className="d-flex">
                    <div>
                        <Button onClick={handleDataPullAPI} variant="outline-warning" size="sm" style={{ fontWeight: '600' }}>
                        Pull Workout Data
                        </Button>
                        <label style={{ marginLeft: '10px' }}>Functional Fitness</label>
                        {functionalfitnessfinished ? <DoneIcon color="success" /> : <CloseIcon />}
                        <label style={{ marginLeft: '10px' }}>Military Prep</label>
                        {militaryprepfinished ? <DoneIcon color="success" /> : <CloseIcon />}
                    </div>
                </Col>
            </Row>
            
        </Container>

    );

}
export default Data






