/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://nexyej62kvcgfpxervly2ehaf4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-x56luuxkrjcx3lwvsa3gqxcapa",
    "aws_cognito_identity_pool_id": "us-east-1:c1c4f057-5f7d-4485-8f67-47590f64a258",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OtGGaDkvU",
    "aws_user_pools_web_client_id": "6g5d37o1rkep33p5i3qd5k5c4t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cronusfit53ca27c814eb41e18a3aa7c926c0f222222944-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
