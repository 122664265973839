/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($amount: Int!, $desc: String!) {
    createPaymentIntent(amount: $amount, desc: $desc) {
      clientSecret
      __typename
    }
  }
`;
export const cancelPaymentIntent = /* GraphQL */ `
  mutation CancelPaymentIntent($clientsecret: String!) {
    cancelPaymentIntent(clientsecret: $clientsecret) {
      success
      __typename
    }
  }
`;
export const createWaivers = /* GraphQL */ `
  mutation CreateWaivers(
    $input: CreateWaiversInput!
    $condition: ModelWaiversConditionInput
  ) {
    createWaivers(input: $input, condition: $condition) {
      id
      key
      publishedDate
      desc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWaivers = /* GraphQL */ `
  mutation UpdateWaivers(
    $input: UpdateWaiversInput!
    $condition: ModelWaiversConditionInput
  ) {
    updateWaivers(input: $input, condition: $condition) {
      id
      key
      publishedDate
      desc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWaivers = /* GraphQL */ `
  mutation DeleteWaivers(
    $input: DeleteWaiversInput!
    $condition: ModelWaiversConditionInput
  ) {
    deleteWaivers(input: $input, condition: $condition) {
      id
      key
      publishedDate
      desc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkoutNotes = /* GraphQL */ `
  mutation CreateWorkoutNotes(
    $input: CreateWorkoutNotesInput!
    $condition: ModelWorkoutNotesConditionInput
  ) {
    createWorkoutNotes(input: $input, condition: $condition) {
      id
      note
      workoutsID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkoutNotes = /* GraphQL */ `
  mutation UpdateWorkoutNotes(
    $input: UpdateWorkoutNotesInput!
    $condition: ModelWorkoutNotesConditionInput
  ) {
    updateWorkoutNotes(input: $input, condition: $condition) {
      id
      note
      workoutsID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkoutNotes = /* GraphQL */ `
  mutation DeleteWorkoutNotes(
    $input: DeleteWorkoutNotesInput!
    $condition: ModelWorkoutNotesConditionInput
  ) {
    deleteWorkoutNotes(input: $input, condition: $condition) {
      id
      note
      workoutsID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCheckListItems = /* GraphQL */ `
  mutation CreateCheckListItems(
    $input: CreateCheckListItemsInput!
    $condition: ModelCheckListItemsConditionInput
  ) {
    createCheckListItems(input: $input, condition: $condition) {
      id
      key
      value
      frequency
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCheckListItems = /* GraphQL */ `
  mutation UpdateCheckListItems(
    $input: UpdateCheckListItemsInput!
    $condition: ModelCheckListItemsConditionInput
  ) {
    updateCheckListItems(input: $input, condition: $condition) {
      id
      key
      value
      frequency
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCheckListItems = /* GraphQL */ `
  mutation DeleteCheckListItems(
    $input: DeleteCheckListItemsInput!
    $condition: ModelCheckListItemsConditionInput
  ) {
    deleteCheckListItems(input: $input, condition: $condition) {
      id
      key
      value
      frequency
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSavedWorkouts = /* GraphQL */ `
  mutation CreateSavedWorkouts(
    $input: CreateSavedWorkoutsInput!
    $condition: ModelSavedWorkoutsConditionInput
  ) {
    createSavedWorkouts(input: $input, condition: $condition) {
      id
      userID
      workoutsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSavedWorkouts = /* GraphQL */ `
  mutation UpdateSavedWorkouts(
    $input: UpdateSavedWorkoutsInput!
    $condition: ModelSavedWorkoutsConditionInput
  ) {
    updateSavedWorkouts(input: $input, condition: $condition) {
      id
      userID
      workoutsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSavedWorkouts = /* GraphQL */ `
  mutation DeleteSavedWorkouts(
    $input: DeleteSavedWorkoutsInput!
    $condition: ModelSavedWorkoutsConditionInput
  ) {
    deleteSavedWorkouts(input: $input, condition: $condition) {
      id
      userID
      workoutsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCheckin = /* GraphQL */ `
  mutation CreateCheckin(
    $input: CreateCheckinInput!
    $condition: ModelCheckinConditionInput
  ) {
    createCheckin(input: $input, condition: $condition) {
      id
      lowestweight
      somewins
      setbacks
      barriers
      sleepquality
      appetite
      energylevel
      othernotes
      waist
      neck
      userID
      generalnotes
      photo_1
      photo_2
      viewed
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCheckin = /* GraphQL */ `
  mutation UpdateCheckin(
    $input: UpdateCheckinInput!
    $condition: ModelCheckinConditionInput
  ) {
    updateCheckin(input: $input, condition: $condition) {
      id
      lowestweight
      somewins
      setbacks
      barriers
      sleepquality
      appetite
      energylevel
      othernotes
      waist
      neck
      userID
      generalnotes
      photo_1
      photo_2
      viewed
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCheckin = /* GraphQL */ `
  mutation DeleteCheckin(
    $input: DeleteCheckinInput!
    $condition: ModelCheckinConditionInput
  ) {
    deleteCheckin(input: $input, condition: $condition) {
      id
      lowestweight
      somewins
      setbacks
      barriers
      sleepquality
      appetite
      energylevel
      othernotes
      waist
      neck
      userID
      generalnotes
      photo_1
      photo_2
      viewed
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFoodEntry = /* GraphQL */ `
  mutation CreateFoodEntry(
    $input: CreateFoodEntryInput!
    $condition: ModelFoodEntryConditionInput
  ) {
    createFoodEntry(input: $input, condition: $condition) {
      id
      food_id
      date
      category
      desc
      protein
      carbs
      fat
      fiber
      calories
      userID
      serving_id
      servingsize
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFoodEntry = /* GraphQL */ `
  mutation UpdateFoodEntry(
    $input: UpdateFoodEntryInput!
    $condition: ModelFoodEntryConditionInput
  ) {
    updateFoodEntry(input: $input, condition: $condition) {
      id
      food_id
      date
      category
      desc
      protein
      carbs
      fat
      fiber
      calories
      userID
      serving_id
      servingsize
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFoodEntry = /* GraphQL */ `
  mutation DeleteFoodEntry(
    $input: DeleteFoodEntryInput!
    $condition: ModelFoodEntryConditionInput
  ) {
    deleteFoodEntry(input: $input, condition: $condition) {
      id
      food_id
      date
      category
      desc
      protein
      carbs
      fat
      fiber
      calories
      userID
      serving_id
      servingsize
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      message
      sender_userid
      receiver_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages(
    $input: UpdateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    updateMessages(input: $input, condition: $condition) {
      id
      message
      sender_userid
      receiver_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMessages = /* GraphQL */ `
  mutation DeleteMessages(
    $input: DeleteMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    deleteMessages(input: $input, condition: $condition) {
      id
      message
      sender_userid
      receiver_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      type
      i_gender
      i_goals
      i_trainingactivity
      i_lifestyleactivity
      i_height
      i_weight
      i_neck
      i_waist
      i_hip
      i_hip_units
      i_body_fat_pct
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      goal_protein
      goal_carb
      goal_fat
      goal_fiber
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      userInfoUserId
      __typename
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      type
      i_gender
      i_goals
      i_trainingactivity
      i_lifestyleactivity
      i_height
      i_weight
      i_neck
      i_waist
      i_hip
      i_hip_units
      i_body_fat_pct
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      goal_protein
      goal_carb
      goal_fat
      goal_fiber
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      userInfoUserId
      __typename
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      type
      i_gender
      i_goals
      i_trainingactivity
      i_lifestyleactivity
      i_height
      i_weight
      i_neck
      i_waist
      i_hip
      i_hip_units
      i_body_fat_pct
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      goal_protein
      goal_carb
      goal_fat
      goal_fiber
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      userInfoUserId
      __typename
    }
  }
`;
export const createArticles = /* GraphQL */ `
  mutation CreateArticles(
    $input: CreateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    createArticles(input: $input, condition: $condition) {
      id
      title
      desc
      storage_path
      date
      data_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArticles = /* GraphQL */ `
  mutation UpdateArticles(
    $input: UpdateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    updateArticles(input: $input, condition: $condition) {
      id
      title
      desc
      storage_path
      date
      data_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArticles = /* GraphQL */ `
  mutation DeleteArticles(
    $input: DeleteArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    deleteArticles(input: $input, condition: $condition) {
      id
      title
      desc
      storage_path
      date
      data_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPrograms = /* GraphQL */ `
  mutation CreatePrograms(
    $input: CreateProgramsInput!
    $condition: ModelProgramsConditionInput
  ) {
    createPrograms(input: $input, condition: $condition) {
      id
      title
      desc
      free
      price
      downloadurl
      data_type
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePrograms = /* GraphQL */ `
  mutation UpdatePrograms(
    $input: UpdateProgramsInput!
    $condition: ModelProgramsConditionInput
  ) {
    updatePrograms(input: $input, condition: $condition) {
      id
      title
      desc
      free
      price
      downloadurl
      data_type
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePrograms = /* GraphQL */ `
  mutation DeletePrograms(
    $input: DeleteProgramsInput!
    $condition: ModelProgramsConditionInput
  ) {
    deletePrograms(input: $input, condition: $condition) {
      id
      title
      desc
      free
      price
      downloadurl
      data_type
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComments = /* GraphQL */ `
  mutation CreateComments(
    $input: CreateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    createComments(input: $input, condition: $condition) {
      id
      comment
      userID
      workoutsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments(
    $input: UpdateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    updateComments(input: $input, condition: $condition) {
      id
      comment
      userID
      workoutsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments(
    $input: DeleteCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    deleteComments(input: $input, condition: $condition) {
      id
      comment
      userID
      workoutsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSubWorkouts = /* GraphQL */ `
  mutation CreateSubWorkouts(
    $input: CreateSubWorkoutsInput!
    $condition: ModelSubWorkoutsConditionInput
  ) {
    createSubWorkouts(input: $input, condition: $condition) {
      id
      group
      grouptitle
      desc
      resultcategory
      required
      timecap
      workoutsID
      WorkoutResults {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSubWorkouts = /* GraphQL */ `
  mutation UpdateSubWorkouts(
    $input: UpdateSubWorkoutsInput!
    $condition: ModelSubWorkoutsConditionInput
  ) {
    updateSubWorkouts(input: $input, condition: $condition) {
      id
      group
      grouptitle
      desc
      resultcategory
      required
      timecap
      workoutsID
      WorkoutResults {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSubWorkouts = /* GraphQL */ `
  mutation DeleteSubWorkouts(
    $input: DeleteSubWorkoutsInput!
    $condition: ModelSubWorkoutsConditionInput
  ) {
    deleteSubWorkouts(input: $input, condition: $condition) {
      id
      group
      grouptitle
      desc
      resultcategory
      required
      timecap
      workoutsID
      WorkoutResults {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkoutResults = /* GraphQL */ `
  mutation CreateWorkoutResults(
    $input: CreateWorkoutResultsInput!
    $condition: ModelWorkoutResultsConditionInput
  ) {
    createWorkoutResults(input: $input, condition: $condition) {
      id
      value
      userID
      subworkoutsID
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkoutResults = /* GraphQL */ `
  mutation UpdateWorkoutResults(
    $input: UpdateWorkoutResultsInput!
    $condition: ModelWorkoutResultsConditionInput
  ) {
    updateWorkoutResults(input: $input, condition: $condition) {
      id
      value
      userID
      subworkoutsID
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkoutResults = /* GraphQL */ `
  mutation DeleteWorkoutResults(
    $input: DeleteWorkoutResultsInput!
    $condition: ModelWorkoutResultsConditionInput
  ) {
    deleteWorkoutResults(input: $input, condition: $condition) {
      id
      value
      userID
      subworkoutsID
      User {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkouts = /* GraphQL */ `
  mutation CreateWorkouts(
    $input: CreateWorkoutsInput!
    $condition: ModelWorkoutsConditionInput
  ) {
    createWorkouts(input: $input, condition: $condition) {
      id
      title
      desc
      date
      SubWorkouts {
        nextToken
        startedAt
        __typename
      }
      Comments {
        nextToken
        startedAt
        __typename
      }
      SavedWorkouts {
        nextToken
        startedAt
        __typename
      }
      workout_type
      WorkoutNotes {
        nextToken
        startedAt
        __typename
      }
      legacy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkouts = /* GraphQL */ `
  mutation UpdateWorkouts(
    $input: UpdateWorkoutsInput!
    $condition: ModelWorkoutsConditionInput
  ) {
    updateWorkouts(input: $input, condition: $condition) {
      id
      title
      desc
      date
      SubWorkouts {
        nextToken
        startedAt
        __typename
      }
      Comments {
        nextToken
        startedAt
        __typename
      }
      SavedWorkouts {
        nextToken
        startedAt
        __typename
      }
      workout_type
      WorkoutNotes {
        nextToken
        startedAt
        __typename
      }
      legacy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkouts = /* GraphQL */ `
  mutation DeleteWorkouts(
    $input: DeleteWorkoutsInput!
    $condition: ModelWorkoutsConditionInput
  ) {
    deleteWorkouts(input: $input, condition: $condition) {
      id
      title
      desc
      date
      SubWorkouts {
        nextToken
        startedAt
        __typename
      }
      Comments {
        nextToken
        startedAt
        __typename
      }
      SavedWorkouts {
        nextToken
        startedAt
        __typename
      }
      workout_type
      WorkoutNotes {
        nextToken
        startedAt
        __typename
      }
      legacy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      sub
      nutrition_info
      nutrition_coaching
      q_experience
      q_medical
      q_calcmacros
      coach_userid
      theme
      image
      image_uri
      Checkins {
        nextToken
        startedAt
        __typename
      }
      Foodentries {
        nextToken
        startedAt
        __typename
      }
      WorkoutResults {
        nextToken
        startedAt
        __typename
      }
      Comments {
        nextToken
        startedAt
        __typename
      }
      SavedWorkouts {
        nextToken
        startedAt
        __typename
      }
      coach_yn
      updatedAt
      default_workout_type
      CheckListItems {
        nextToken
        startedAt
        __typename
      }
      workout_logs
      WorkoutNotes {
        nextToken
        startedAt
        __typename
      }
      Programs {
        nextToken
        startedAt
        __typename
      }
      termsandconditions
      nutritionwaiver
      programmingwaiv
      newuser
      requestdelete
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      sub
      nutrition_info
      nutrition_coaching
      q_experience
      q_medical
      q_calcmacros
      coach_userid
      theme
      image
      image_uri
      Checkins {
        nextToken
        startedAt
        __typename
      }
      Foodentries {
        nextToken
        startedAt
        __typename
      }
      WorkoutResults {
        nextToken
        startedAt
        __typename
      }
      Comments {
        nextToken
        startedAt
        __typename
      }
      SavedWorkouts {
        nextToken
        startedAt
        __typename
      }
      coach_yn
      updatedAt
      default_workout_type
      CheckListItems {
        nextToken
        startedAt
        __typename
      }
      workout_logs
      WorkoutNotes {
        nextToken
        startedAt
        __typename
      }
      Programs {
        nextToken
        startedAt
        __typename
      }
      termsandconditions
      nutritionwaiver
      programmingwaiv
      newuser
      requestdelete
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      sub
      nutrition_info
      nutrition_coaching
      q_experience
      q_medical
      q_calcmacros
      coach_userid
      theme
      image
      image_uri
      Checkins {
        nextToken
        startedAt
        __typename
      }
      Foodentries {
        nextToken
        startedAt
        __typename
      }
      WorkoutResults {
        nextToken
        startedAt
        __typename
      }
      Comments {
        nextToken
        startedAt
        __typename
      }
      SavedWorkouts {
        nextToken
        startedAt
        __typename
      }
      coach_yn
      updatedAt
      default_workout_type
      CheckListItems {
        nextToken
        startedAt
        __typename
      }
      workout_logs
      WorkoutNotes {
        nextToken
        startedAt
        __typename
      }
      Programs {
        nextToken
        startedAt
        __typename
      }
      termsandconditions
      nutritionwaiver
      programmingwaiv
      newuser
      requestdelete
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserCheckListItems = /* GraphQL */ `
  mutation CreateUserCheckListItems(
    $input: CreateUserCheckListItemsInput!
    $condition: ModelUserCheckListItemsConditionInput
  ) {
    createUserCheckListItems(input: $input, condition: $condition) {
      id
      checkListItemsId
      userId
      checkListItems {
        id
        key
        value
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserCheckListItems = /* GraphQL */ `
  mutation UpdateUserCheckListItems(
    $input: UpdateUserCheckListItemsInput!
    $condition: ModelUserCheckListItemsConditionInput
  ) {
    updateUserCheckListItems(input: $input, condition: $condition) {
      id
      checkListItemsId
      userId
      checkListItems {
        id
        key
        value
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserCheckListItems = /* GraphQL */ `
  mutation DeleteUserCheckListItems(
    $input: DeleteUserCheckListItemsInput!
    $condition: ModelUserCheckListItemsConditionInput
  ) {
    deleteUserCheckListItems(input: $input, condition: $condition) {
      id
      checkListItemsId
      userId
      checkListItems {
        id
        key
        value
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserPrograms = /* GraphQL */ `
  mutation CreateUserPrograms(
    $input: CreateUserProgramsInput!
    $condition: ModelUserProgramsConditionInput
  ) {
    createUserPrograms(input: $input, condition: $condition) {
      id
      programsId
      userId
      programs {
        id
        title
        desc
        free
        price
        downloadurl
        data_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserPrograms = /* GraphQL */ `
  mutation UpdateUserPrograms(
    $input: UpdateUserProgramsInput!
    $condition: ModelUserProgramsConditionInput
  ) {
    updateUserPrograms(input: $input, condition: $condition) {
      id
      programsId
      userId
      programs {
        id
        title
        desc
        free
        price
        downloadurl
        data_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserPrograms = /* GraphQL */ `
  mutation DeleteUserPrograms(
    $input: DeleteUserProgramsInput!
    $condition: ModelUserProgramsConditionInput
  ) {
    deleteUserPrograms(input: $input, condition: $condition) {
      id
      programsId
      userId
      programs {
        id
        title
        desc
        free
        price
        downloadurl
        data_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        email
        sub
        nutrition_info
        nutrition_coaching
        q_experience
        q_medical
        q_calcmacros
        coach_userid
        theme
        image
        image_uri
        coach_yn
        updatedAt
        default_workout_type
        workout_logs
        termsandconditions
        nutritionwaiver
        programmingwaiv
        newuser
        requestdelete
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
