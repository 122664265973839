

import { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listMessages, listUsers, getUser } from '../../graphql/queries';
import { createMessages } from '../../graphql/mutations';

import './messages.css'

//bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';



function Messages(props) {

    function MessagingPage() {

      const [messageGroups, setMessageGroups] = useState([]);
      const [activeMessageGroup, setActiveMessageGroup] = useState(null);
      const [authuser, setAuthUser] = useState(props.user);
      const [newMessage, setNewMessage] = useState('');

      // Inside the Messages component
      const messageListRef = useRef(null);

    
      useEffect(() => {

        
        async function fetchMessageGroups() {
          try {

       
            // get current authenticated user's sub
            const {
              attributes: { sub },
            } = await Auth.currentAuthenticatedUser();
  
    
            // query User table to get user's id based on sub value
            const userData = await API.graphql(
              graphqlOperation(listUsers, {
                filter: { sub: { eq: sub } },
              })
            );
            const userId = userData.data.listUsers.items[0].id;

            
            setAuthUser(userId)
            console.log(userId)
    
            // query Messages table to get all messages where the authenticated user is the sender or receiver
            const messagesData = await API.graphql(
              graphqlOperation(listMessages, {
                filter: {
                  or: [
                    { sender_userid: { eq: userId } },
                    { receiver_userid: { eq: userId } },
                  ],
                },
              })
            );

            console.log('data: ' + JSON.stringify(messagesData))

            const messages_unfiltered = messagesData.data.listMessages.items;
            const messages_filtered = messages_unfiltered.filter((item) => item._deleted != true);

            // Fetch all user data using listUsers operation
            const allUserData = await API.graphql(graphqlOperation(listUsers));

            // Create a user map for easy lookup by ID
            const userMap = {};
            allUserData.data.listUsers.items.forEach((user) => {
              userMap[user.id] = user;
            });

            

            // Loop through each message and add sender's user name
            const messages = messages_filtered
              .filter((message) => !message._deleted) // Filter out deleted messages
              .map((message) => {
                const senderUserId = message.sender_userid;
                const senderName = userMap[senderUserId].name;

                return {
                  ...message,
                  sender_name: senderName,
                };
              });

              console.log('this: ' + JSON.stringify(messages))
            // loop through the messages and group them based on sender/receiver
            const groupedMessages = {};
            for (const message of messages) {
              const senderId = message.sender_userid;
              const receiverId = message.receiver_userid;
    
              if (senderId === userId) {
                // message sent by the authenticated user
                if (receiverId in groupedMessages) {
                  groupedMessages[receiverId].push(message);
                } else {
                  groupedMessages[receiverId] = [message];
                }
              } else {
                // message received by the authenticated user
                if (senderId in groupedMessages) {
                  groupedMessages[senderId].push(message);
                } else {
                  groupedMessages[senderId] = [message];
                }
              }
            }
            
            // Create the messageGroups array using the collected user data
            const messageGroups = [];
            for (const groupId in groupedMessages) {
              const userId_temp =
                groupId === userId
                  ? groupedMessages[groupId][0].receiver_userid
                  : groupId;
            
              const groupName = userMap[userId_temp].name;
            
              const messagesInGroup = groupedMessages[groupId].sort((messageA, messageB) => {
                const timestampA = new Date(messageA.createdAt).getTime();
                const timestampB = new Date(messageB.createdAt).getTime();
                return timestampB - timestampA; // Compare timestamps in descending order for newest messages first
              });
            
              messageGroups.push({
                id: groupId,
                name: groupName,
                messages: messagesInGroup, // Use the sorted messages array
              });
            }
    
            // Sort message groups by the newest message
            const sortedMessageGroups = messageGroups.slice().sort((groupA, groupB) => {
              const timestampA = groupA.messages.length > 0 ? new Date(groupA.messages[0].createdAt).getTime() : 0;
              const timestampB = groupB.messages.length > 0 ? new Date(groupB.messages[0].createdAt).getTime() : 0;
              return timestampA - timestampB; // Compare timestamps in ascending order for oldest messages first
            });

            console.log('Setting message groups sorted: ' + JSON.stringify(sortedMessageGroups))

            setMessageGroups(sortedMessageGroups);

            //Default and select the top message
            if(sortedMessageGroups.length > 0) {
              setActiveMessageGroup(sortedMessageGroups[0].id)
            }
          } catch (error) {
            console.log("Error fetching message groups:", error);
          }
        }
        

        fetchMessageGroups();
      }, []);

      useEffect(() => {
        // Scroll to the bottom of the message list
        if (messageListRef.current) {
          messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
      }, [activeMessageGroup, messageGroups]);

      /*
      const sortedMessageGroups = messageGroups.slice().sort((groupA, groupB) => {
        // Get the timestamps of the newest messages in each group
        const timestampA = groupA.messages.length > 0 ? new Date(groupA.messages[0].createdAt).getTime() : 0;
        const timestampB = groupB.messages.length > 0 ? new Date(groupB.messages[0].createdAt).getTime() : 0;
        
        // Sort in descending order (newest first)
        return timestampB - timestampA;
      });
      */
      

      useEffect(() => {
          setFilteredMessageGroups(messageGroups);
        }, [messageGroups]);

        /*
        async function handleSendMessage() {
          if (newMessage.trim() === '') {
            return;
          }
        
          const senderuserid = props.user.id;
          const sendername = props.user.name;
          const receiverUserId = activeMessageGroup;
        
          try {
            const messageData = {
              message: newMessage,
              sender_userid: senderuserid,
              receiver_userid: receiverUserId,
  
            };
        
            // Create a new message in the backend
            const createdMessage = await API.graphql(
              graphqlOperation(createMessages, { input: messageData })
            );           
  
        
            // Add in sender name
            const updatedMessageData = {
              ...messageData,
              sender_name: sendername,
              createdAt: createdMessage.data.createMessages.createdAt, // Add createdAt value
            };
  
      
        
            // Update the message groups with the new message
            setMessageGroups((prevMessageGroups) => {
              const updatedMessageGroups = [...prevMessageGroups];
  
              // Find the active message group
              const activeMessageGroupIndex = updatedMessageGroups.findIndex(
                (group) => group.id === activeMessageGroup
              );
  
  
              if (activeMessageGroupIndex !== -1) {
                // Clone the active message group and add the new message
                const updatedGroup = {
                  ...updatedMessageGroups[activeMessageGroupIndex],
                  messages: [...updatedMessageGroups[activeMessageGroupIndex].messages, updatedMessageData],
                };
  
                // Update the message group in the array
                updatedMessageGroups[activeMessageGroupIndex] = updatedGroup;
              }
  
              return updatedMessageGroups;
            });
        
            // Clear the input field after successful send
            setNewMessage('');
          } catch (error) {
            console.log('Error sending message:', error);
          }
        }
        */

        async function handleSendMessage() {
          if (newMessage.trim() === '') {
            return;
          }
        
          const senderuserid = props.user.id;
          const sendername = props.user.name;
          const receiverUserId = activeMessageGroup;
        
          try {
            const messageData = {
              message: newMessage,
              sender_userid: senderuserid,
              receiver_userid: receiverUserId,
            };
        
            // Create a new message in the backend
            const createdMessage = await API.graphql(
              graphqlOperation(createMessages, { input: messageData })
            );
        
            // Add in sender name and createdAt value
            const updatedMessageData = {
              ...messageData,
              sender_name: sendername,
              createdAt: createdMessage.data.createMessages.createdAt,
            };
        
            // Update the message groups with the new message
            setMessageGroups((prevMessageGroups) => {
              const updatedMessageGroups = [...prevMessageGroups];
        
              // Find the active message group
              const activeMessageGroupIndex = updatedMessageGroups.findIndex(
                (group) => group.id === activeMessageGroup
              );
        
              if (activeMessageGroupIndex !== -1) {
                // Clone the active message group and add the new message
                const updatedGroup = {
                  ...updatedMessageGroups[activeMessageGroupIndex],
                  messages: [...updatedMessageGroups[activeMessageGroupIndex].messages, updatedMessageData],
                };
        
                // Sort the messages within the group based on createdAt
                updatedGroup.messages.sort((messageA, messageB) => {
                  const timestampA = new Date(messageA.createdAt).getTime();
                  const timestampB = new Date(messageB.createdAt).getTime();
                  return timestampB - timestampA;
                });
        
                // Update the message group in the array
                updatedMessageGroups[activeMessageGroupIndex] = updatedGroup;
              }
        
              return updatedMessageGroups;
            });
        
            // Clear the input field after successful send
            setNewMessage('');
          } catch (error) {
            console.log('Error sending message:', error);
          }
        }
        

      const [searchQuery, setSearchQuery] = useState('');
      const [filteredMessageGroups, setFilteredMessageGroups] = useState(messageGroups);

      const handleSearchChange = (query) => {
        setSearchQuery(query);
        if (query.trim() === '') {
          setFilteredMessageGroups(messageGroups); // Show all clients when search query is empty
        } else {
          const filteredGroups = messageGroups.filter((group) =>
            group.name.toLowerCase().includes(query.toLowerCase())
          );
          setFilteredMessageGroups(filteredGroups);
        }
      };


      return (
        <Row style={{ height: '100%' }}>
          {/* Left item */}
          <Col sm={5} md={4} lg={3} xl={2} xxl={2} style={{ height: '100%', display: 'flex', flexDirection: 'column', borderRight: '0.5px solid white', paddingLeft: 5, paddingRight: 5 }}>
            <div>
              <h2 style={{color: 'white', textAlign: 'center'}}>Clients</h2>
            </div>
            <Row className="mb-3">
              <Col>
                    <Form.Control required type="text" placeholder="Search..." value={searchQuery} onChange={(e) => handleSearchChange(e.target.value)}/>
              </Col>
            </Row>
            <div className="message-groups">
              {filteredMessageGroups.map((group) => (
                <div
                  key={group.id}
                  className={`message-group${activeMessageGroup === group.id ? ' active' : ''}`}
                  onClick={() => setActiveMessageGroup(group.id)}
                >
                  <label style={{fontSize: 20, fontWeight: '400'}}>{group.name}</label>
                </div>
              ))}
            </div>
          </Col>

          {/* Right item */}
          <Col style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 15}}>
            {/* Content for the right item */}
            {activeMessageGroup && (
              <div className="message-list" style={{ flex: 1, overflowY: 'scroll' }}>
                {messageGroups
                  .find((group) => group.id === activeMessageGroup)
                  .messages.slice()
                  .reverse().map((message) => (
                    <div
                      style={{
                        alignItems: message.sender_userid === props.user.id ? 'flex-end' : 'flex-start',
                        flexDirection: 'column',
                      }}
                      key={message.id}
                      className={`message ${message.sender_userid === props.user.id ? 'sent' : 'received'}`}
                    >
                      <div className="message-header">
                        <h4 className="message-sender">{message.sender_name}</h4>
                      </div>
                      <div className={`message-bubble ${message.sender_userid === props.user.id ? 'sent-bubble' : 'received-bubble'}`}>
                        <p className="message-text">{message.message}</p>
                        <p className="message-date">{new Date(message.createdAt).toISOString().split('T')[0]}</p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
              <Stack direction="horizontal" gap={3}>
                <Form.Control
                  className="me-auto"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Enter your message..."
                />
                <Button onClick={handleSendMessage} variant="warning">
                  Send
                </Button>
              </Stack>
          </Col>
        </Row>
        );
      }
    

    return (
      <Container fluid style={{ height: '100vh' }}>
                <MessagingPage />
      </Container>
    );

}



export default Messages


