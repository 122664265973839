
import {useState, useEffect, useRef} from 'react';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import { listUsers, listCheckListItems, listArticles, userCheckListItemsByUserId, getUser  } from '../../graphql/queries';
import { updateUser, updateUserInfo,createUserCheckListItems, deleteUserCheckListItems, createArticles, updateCheckin, createCheckListItems } from '../../graphql/mutations';
import Switch from 'react-switch';
import Select from 'react-select';

import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import Dropzone from 'react-dropzone';

//Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import 'react-tabs/style/react-tabs.css';

import './nutrition.css'

//bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

function Nutrition() {

  const [showalert, setShowAlert] = useState(false);
  const [alerttitle, setAlertTitle] = useState('');

    const getAllUserInfo = /* GraphQL */ `
    query GetUserInfo {
        listUserInfos {
            items {
            User {
                id
                name
                nutrition_coaching
                _deleted
            }
            id
            goal_fiber
            goal_fat
            goal_carb
            goal_protein
            _version
            _deleted
          }
        }
    }
    `;

    const listCheckinsWithUsers  = /* GraphQL */ `
    query ListCheckinsWithUsers {
      listCheckins {
        items {
          id
          lowestweight
          somewins
          setbacks
          barriers
          sleepquality
          appetite
          energylevel
          othernotes
          waist
          neck
          generalnotes
          images
          viewed
          userID
          createdAt
          _version
          User {
            id
            name
         }
        }
      }
    }`;

    // Define the GraphQL query
    const listFoodEntriesWithUsers = `
    query ListFoodEntriesWithUsers{
      listFoodEntries{
        items {
          id
          food_id
          date
          category
          desc
          protein
          carbs
          fat
          fiber
          calories
          serving_id
          servingsize
          quantity
          createdAt
          _version
          _deleted
          User {
            id
            name
         }
        }
      }
    }
    `;


    const getUserWithCheckList = /* GraphQL */ `
      query GetUser($id: ID!) {
        getUser(id: $id) {
          id
          name
          email
          sub
          nutrition_info
          nutrition_coaching
          q_experience
          q_medical
          q_calcmacros
          coach_userid
          theme
          image
          image_uri
          coach_yn
          updatedAt
          default_workout_type
          CheckListItems {
            id
            nextToken
            startedAt
          }
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    `;

    const ArticleList = () => {
      const [articles, setArticles] = useState([]);
      const [searchQuery, setSearchQuery] = useState("");
      const [selectedArticle, setSelectedArticle] = useState(undefined);
      const [refresh, setRefresh] = useState(false);

      const [showUploadContainer, setShowUploadContainer] = useState(false);


      useEffect(() => {
        fetchArticles();
      }, [refresh]);

      const fetchArticles = async () => {
        try {
          const response = await API.graphql(
            graphqlOperation(listArticles, {
              filter: {
                _deleted: {
                  ne: true
                }
              }
            })
          );

            //console.log(response)

            const articlesList = response.data.listArticles.items;
            setArticles(articlesList);

          } catch (error) {
            console.log("Error fetching users:", error);
          }
      };

      const handleSelectedUser = (article) => {

        setSelectedArticle(article)

        

      }

      const filteredArticles = articles.filter(
        (article) =>
          article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          article.desc.toLowerCase().includes(searchQuery.toLowerCase())
      );

      const handleToggleUploadContainer = () => {
        setShowUploadContainer(!showUploadContainer);
      };

      const UploadBox = () => {
        const [file, setFile] = useState(null);
        const [date, setDate] = useState(new Date());
        const [title, setTitle] = useState('')
        const [description, setDescription] = useState('');
  
        const handleFileUpload = async (event) => {
          event.preventDefault();

          const temp_date = new Date(date);
          const formattedDate = temp_date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
          
          /*
          //Image Testing
          try {
          const path = 'profileimages/';
            const fileName = `${path}${Date.now()}-${file.name}`;
    
            // Upload the file to S3
            await Storage.put(fileName, file, {
              contentType: file.type,
            });
          } catch (error) {
            console.error("Error: ", error)
          }
          */
          

        
          try {

            const path = 'nutritionpdfs/';
            const fileName = `${path}${Date.now()}-${file.name}`;
    
            // Upload the file to S3
            await Storage.put(fileName, file, {
              contentType: file.type,
            });
            
            // Insert the file information into the Articles table
            const articleData = {
              title,
              desc: description,
              storage_path: fileName,
              date: formattedDate,
              data_type: file.type,
              _version: 1
            };
    
              //console.log(articleData)
    
  
              await API.graphql(graphqlOperation(createArticles, { input: articleData }));
    
              //console.log('File uploaded successfully');
    
              setShowUploadContainer(false)

               // Show the modal
               setShowAlert(true);
               setAlertTitle("Document uploaded!")
       
               // Hide the modal after 2 seconds
               setTimeout(() => {
                 setShowAlert(false);
               }, 2000);

               
               //Trigger
               setRefresh(!refresh)

          } catch (error) {
            console.error('Error uploading file:', error);
            // Handle the error appropriately
          }
          

        
        };
  
        const handleFileChange = (event) => {
          const selectedFile = event.target.files[0];
          //console.log(selectedFile)
          setFile(selectedFile);

          //console.log(selectedFile)
        }
  
      
        return (
  
          <Form>
            <Row className='mb-3'>
              <Col sm={12} lg={3}>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Control required type="file" onChange={handleFileChange} />
                </Form.Group>
              </Col>
              <Col sm={12} lg={3}>
                <FloatingLabel
                      controlId="dateFloatingInput"
                      label="Date"
                >
                  <Form.Control
                    required
                    type="date" 
                    value={date.toISOString().split('T')[0]} 
                    onChange={(e) => setDate(new Date(e.target.value))}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <FloatingLabel controlId="titleFloatingInput" label="Title" className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col sm={12} lg={6}>
                <FloatingLabel controlId="descFloatingInput" label="Program Description">
                  <Form.Control
                    required
                    as="textarea"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    style={{ minHeight: '150px' }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Button style={{marginTop: 0, marginRight: 5}} onClick={(e) => handleFileUpload(e)} variant="warning" type="submit">Upload</Button>
                <Button style={{marginTop: 0}} onClick={() => setShowUploadContainer(!showUploadContainer)} variant="warning" type="button">Cancel</Button>
              </Col>
            </Row>
          </Form>
        );
      };


      const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

      return(

        <Container className='p-3'>
          <Row className="g-3 mb-3">
            <Col>
              <h2>Articles</h2>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button onClick={handleToggleUploadContainer} variant="warning">{showUploadContainer ? 'Hide Upload Box' : 'Upload'}</Button>
            </Col>
          </Row>
          {showUploadContainer ? (
              <UploadBox />
            ) : (
              <>
                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
                  <Col>
                      <Form.Control type="text" placeholder="Search Articles" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} data-bs-theme="dark"/>
                  </Col>
                </Row>
                <Row>
                  <div className="users-grid">
                    {filteredArticles
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((article) => (

                        <Card key={article.id} border="light" style={{ width: '18rem' }} className="custom-card" onClick={() => {
                          handleSelectedUser(article);
                        }}>
                          <Card.Header style={{justifyContent: 'space-between', display: 'flex'}}>
                            <div>
                              {article.title}
                            </div>
                            <div>
                              <label>{article.date}</label>
                            </div>
                          </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                  {truncateText(article.desc, 75)} ...
                              </Card.Text>
                            </Card.Body>
                          </Card>
                      ))}
                  </div>
                </Row>
              </>
            )}
        </Container>

        
      )

    };

    const [checkins, setCheckIns] = useState([]);
    const [checkins_new, setCheckInsNew] = useState([]);
    const [foodentries, setFoodEntries] = useState([]);
    
    useEffect(() => {
      fetchCheckins();
      fetchFoodLog();
    }, []);

 
    const fetchImageUrls = async (checkins) => {
      try {
        // Ensure checkins is always an array
        const checkinList = Array.isArray(checkins) ? checkins : [checkins];
    
        // Map over each object in the dataset
        const updatedData = await Promise.all(checkinList.map(async (checkin) => {
          // Check if there are images in the current check-in
          if (checkin.images && checkin.images.length > 0) {
            // Retrieve the URL for each image and replace the image name with the URL
            const imageUrls = await Promise.all(checkin.images.map(async (imageName) => {
              try {
                const imageUrl = await Storage.get(imageName);
                return imageUrl;
              } catch (error) {
                console.error('Error retrieving image URL:', error);
                return null;
              }
            }));
            // Replace the image names with the URLs in the current check-in
            return { ...checkin, images: imageUrls };
          } else {
            // If there are no images, return the check-in as is
            return checkin;
          }
        }));
        // Return the updated dataset containing image URLs
        return updatedData;
      } catch (error) {
        console.error('Error updating dataset with image URLs:', error);
        return []; // Return an empty array in case of error
      }
    };

    const fetchCheckins = async () => {
      try {
          const response = await API.graphql(graphqlOperation(listCheckinsWithUsers))

          const checkin_List = response.data.listCheckins.items;

          const remove_deleted_list = checkin_List.filter((c) => !c._deleted)

          //Loop over remove_deleted list and fetch image urls
          const list_checkins_with_urls = await fetchImageUrls(remove_deleted_list)

          setCheckIns(list_checkins_with_urls)

          const filtered_list = list_checkins_with_urls.filter((c) => !c.viewed)

          //console.log('this one: ' + JSON.stringify(filtered_list))
          //const list_with_urls = await fetchImageUrls(filtered_list)

          //console.log('urls: ' + JSON.stringify(list_with_urls))

          setCheckInsNew(filtered_list)
          
        } catch (error) {
          console.error("Error fetching users:", error);
        }
  };

  const fetchFoodLog = async () => {
    try {
        const response = await API.graphql(graphqlOperation(listFoodEntriesWithUsers))

        const food_entry_list = response.data.listFoodEntries.items;

        const remove_deleted_list = food_entry_list.filter((c) => !c._deleted)
        setFoodEntries(remove_deleted_list)
        
      } catch (error) {
        console.error("Error fetching Food Log:", error);
      }
  };


    const markAsViewed = async (id, version) => {
        try {
          // Call the updateCheckin mutation
          const updatedCheckin = await API.graphql(
            graphqlOperation(updateCheckin, {
              input: {
                id: id,
                viewed: true, // Update the viewed flag to true
                _version: version
              },
            })
          );

          // Handle the updated data, either by fetching updated data or updating the local state
          console.log('Updated checkin:', updatedCheckin);
            
          // Remove the viewed check-in from the checkins array
          const updatedCheckins = checkins.filter(checkin => checkin.id !== id);
          setCheckIns(updatedCheckins);

          
        } catch (error) {
          console.error('Error updating checkin:', error);
      }
    };


    function CheckinList({checkin_list = [], userview = false}) {
      
      const [expandedId, setExpandedId] = useState(null);

      const toggleExpand = (id) => {
        setExpandedId((prevId) => (prevId === id ? null : id));
      };

     
    
    const excludedKeys = ['User', 'id', '_version', 'userID', 'viewed', 'createdAt'];
    const checkin_titles = {
      "lowestweight": "Lowest Weight",
      "setbacks": "Set Backs",
      "somewins": "Some Wins",
      "barriers": "Barriers",
      "sleepquality": "Sleep Quality (1-10)",
      "appetite": "Appetite (1-10)",
      "energylevel": "Energy Level (1-10)",
      "othernotes": "Other Notes",
      "generalnotes": "General Notes",
      "waist": "Waist",
      "neck": "Neck",
      "images": "Photos"
    };

    console.log('here: ' + JSON.stringify(checkin_list))
    
      return (
        <div>
          {checkin_list.length > 0 ? (
            checkin_list.map((checkin) => (
              <div key={checkin.id}>
                <div
                    onClick={() => toggleExpand(checkin.id)}
                    style={{
                      width: '25%',
                      cursor: 'pointer',
                      padding: 10,
                      borderBottomColor: 'white',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 0.5,
                      borderRadius: 3,
                      marginBottom: 10, // Add some spacing between items
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent:'space-between'
                    }}
                  >
                     {userview ? (
                      <div>
                          {new Date(checkin.createdAt).toISOString().split('T')[0]}
                      </div>
                     ) : (
                      <>
                        <div>
                          {checkin.User.name}
                        </div>
                        <div>
                          
                        </div>
                      </>
                     )}
                   
                </div>
                {expandedId === checkin.id && (
                  <div style={{
                    padding: 10,
                    boxShadow: '0px 0px 5px rgba(255, 255, 255, 0.5)',
                    maxHeight: 500,
                    overflowY: 'auto'
                  }}>
                    {userview ? (
                      <></>
                    ) : (
                      <div style={{ marginBottom: 15 }}>
                        <Button
                          onClick={() => markAsViewed(checkin.id, checkin._version)}
                          style={{ marginTop: 10, marginRight: 5 }}
                          variant="outline-warning"
                        >
                          Mark as Viewed
                        </Button>
                      </div>
                    )}
                    
                    <div style={{
                      display: 'grid',
                      gap: '10px',
                      gridTemplateColumns: 'repeat(2, 1fr)' // Two columns
                    }}>
                      {Object.entries(checkin).map(([key, value]) => (
                        excludedKeys.includes(key) ? null : (
                          <div key={key} style={{
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column', // Stack content vertically
                          }}>
                            <div>
                              <label style={{ fontSize: 18, fontWeight: '500', textDecoration: 'none', position: 'relative', marginBottom: 3 }}>
                                {checkin_titles[key] ? checkin_titles[key] : key}
                                <span style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', borderBottom: '0.5px solid white', paddingBottom: '3px' }}></span>
                              </label>
                            </div>
                            {key === 'images' ? (
                              <div>
                                {value.map((image, index) => (
                                  <img
                                    key={index}
                                    src={image}
                                    alt={`Image ${index}`}
                                    style={{ width: '100%', height: 'auto', marginBottom: 10 }}
                                  />
                                ))}
                                </div>
                              ) : (
                                <div style={{ flex: 1 }}>
                                  <p style={{fontWeight: '200'}}>{value}</p>
                                </div>
                              )}
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
              <></>
          )}
        </div>
      );
    }


    function FoodEntryList({ groupedData }) {
      // Function to calculate totals for a specific meal category
      const calculateMealTotals = (items, category) => {
        let totalCalories = 0;
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalFiber = 0;
    
        for (const item of items) {
          if (item.category === category) {
            totalCalories += item.calories || 0;
            totalProtein += item.protein || 0;
            totalCarbs += item.carbs || 0;
            totalFiber += item.fiber || 0;
          }
        }
    
        return { totalCalories, totalProtein, totalCarbs, totalFiber };
      };
    
      // Define expandedId state and toggleExpand function
      const [expandedId, setExpandedId] = useState(null);
    
      const toggleExpand = (date) => {
        setExpandedId((prevId) => (prevId === date ? null : date));
      };
    
      return (
        <div>
          {groupedData.map((dateData) => (
            <Row key={dateData.date} className="mt-3" style={{justifyContent: 'center'}}>
              <div
                style={{ cursor: 'pointer', justifyContent: 'center', display: 'flex', width: '60%', padding: 10, borderBottomColor: 'white', borderBottomStyle: 'solid', borderBottomWidth: 0.5, borderRadius: 3, marginBottom: 10 }}
                onClick={() => toggleExpand(dateData.date)} // Toggle expand/collapse on click
              >
                <h3>{dateData.date}</h3>
              </div>
    
              {expandedId === dateData.date && ( // Only render if the date is expanded
                <Row className='pb-3' style={{borderBottom: '1px solid #ccc'}}>
                  <Col md={3}>
                    <h3 style={{color: '#F8BE13'}}>Breakfast</h3>
                    <ul style={{listStyleType: 'none', paddingLeft: 5}}>
                      {dateData.items
                        .filter((item) => item.category === 'BREAKFAST')
                        .map((item) => (
                          <li key={item.id} className="mb-1">
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <p style={{ marginBottom: 3, flexBasis: '100%' }}>{item.desc} - {item.calories} cals</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.protein} p</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.carbs} carbs</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fat} fat</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fiber} fiber</p>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div>
                      <strong>Total Calories: {calculateMealTotals(dateData.items, 'BREAKFAST').totalCalories}</strong>
                    </div>
                  </Col>
    
                  <Col md={3}>
                    <h3 style={{color: '#F8BE13'}}>Lunch</h3>
                    <ul style={{listStyleType: 'none', paddingLeft: 5}}>
                      {dateData.items
                        .filter((item) => item.category === 'LUNCH')
                        .map((item) => (
                          <li key={item.id}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <p style={{ marginBottom: 3, flexBasis: '100%' }}>{item.desc} - {item.calories} cals</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.protein} p</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.carbs} carbs</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fat} fat</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fiber} fiber</p>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div>
                      <strong>Total Calories: {calculateMealTotals(dateData.items, 'LUNCH').totalCalories}</strong>
                    </div>
                  </Col>
    
                  <Col md={3}>
                    <h3 style={{color: '#F8BE13'}}>Dinner</h3>
                    <ul style={{listStyleType: 'none', paddingLeft: 5}}>
                      {dateData.items
                        .filter((item) => item.category === 'DINNER')
                        .map((item) => (
                          <li key={item.id}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <p style={{ marginBottom: 3, flexBasis: '100%' }}>{item.desc} - {item.calories} cals</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.protein} p</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.carbs} carbs</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fat} fat</p>
                              <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fiber} fiber</p>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div>
                      <strong>Total Calories: {calculateMealTotals(dateData.items, 'DINNER').totalCalories}</strong>
                    </div>
                  </Col>
    
                  <Col md={3}>
                    <h3 style={{color: '#F8BE13'}}>Snacks</h3>
                    <ul style={{listStyleType: 'none', paddingLeft: 5}}>
                      {dateData.items
                        .filter((item) => item.category === 'SNACKS')
                        .map((item) => (
                          <li key={item.id}>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <p style={{ marginBottom: 3, flexBasis: '100%' }}>{item.desc} - {item.calories} cals</p>
                            <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.protein} p</p>
                            <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.carbs} carbs</p>
                            <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fat} fat</p>
                            <p style={{ marginBottom: 3, flexBasis: '25%', fontSize: 13 }}>{item.fiber} fiber</p>
                          </div>
                        </li>
                        ))}
                    </ul>
                    <div>
                      <strong>Total Calories: {calculateMealTotals(dateData.items, 'SNACKS').totalCalories}</strong>
                    </div>
                  </Col>
                </Row>
              )}
            </Row>
          ))}
        </div>
      );
    }
    


    function FoodLog({ list = [] }) {
      return <FoodEntryList groupedData={list} />;
    }
    
  
    function UserList() {
        const [users, setUsers] = useState([]);
        const [usersinfo, setUsersInfo] = useState([]);
        const [searchQuery, setSearchQuery] = useState("");
        const [selectedUser, setSelectedUser] = useState(undefined);
        const [selectedUserInfo, setSelectedUserInfo] = useState(undefined);

        //Editable Fields
        const [editedprotein, setEditedProtein] = useState("");
        const [editedcarbs, setEditedCarbs] = useState("");
        const [editedfat, setEditedFat] = useState("");
        const [editedfiber, setEditedFiber] = useState("");

        //Checklist
        const [checklistItems, setChecklistItems] = useState([]);
        const [selectedItems, setSelectedItems] = useState([]);

        //Modals
        const [showModal, setShowModal] = useState(false);

        //Exandables
        const [expandcheckins, setExpandCheckins] = useState(false);
        const [expandfoodintake, setExpandFoodIntake] = useState(false);
        const [selectedusercheckins, setSelectedUserCheckins] = useState([]);
        const [selecteduserfoodentries, setSelectedUserFoodEntries] = useState([]);
        
    
        useEffect(() => {
          fetchUsers();
        }, []);
    
        useEffect(() => {

          fetchUserInfo();


          if (selectedUserInfo) {
            
                setEditedProtein(selectedUserInfo.goal_protein);
                setEditedCarbs(selectedUserInfo.goal_carb);
                setEditedFat(selectedUserInfo.goal_fat);
                setEditedFiber(selectedUserInfo.goal_fiber);
            
    
          } else {

                setEditedProtein("");
                setEditedCarbs("");
                setEditedFat("");
                setEditedFiber("");
          }
        }, [selectedUserInfo]);

        useEffect(() => {
          fetchChecklistItems();
        }, []);
        
        const fetchChecklistItems = async () => {
          try {
            const response = await API.graphql(graphqlOperation(listCheckListItems));
            const items = response.data.listCheckListItems.items;

             // Filter out rows with deleted = true
            const filteredItems = items.filter(item => item._deleted !== true);


            setChecklistItems(filteredItems);
          } catch (error) {
            console.log("Error fetching checklist items:", error);
          }
        };

        const handleItemSelect = (event) => {
          const itemId = event.target.value;
          const selectedItem = checklistItems.find((item) => item.id === itemId);
          if (selectedItem && !selectedItems.some((item) => item.id === itemId)) {
            setSelectedItems((prevItems) => [...prevItems, selectedItem]);
          }
        };

        const handleRemoveItem = (itemId) => {
          setSelectedItems((prevItems) =>
            prevItems.filter((item) => item.id !== itemId)
          );
        };
        
        const fetchUsers = async () => {
            try {
                const response = await API.graphql(graphqlOperation(listUsers, {
                  filter: {
                    nutrition_coaching: {
                      eq: true
                    },
                    coach_yn: {
                      ne: true
                    }
                  }
                }));
                const userList = response.data.listUsers.items;
                setUsers(userList);
              } catch (error) {
                console.log("Error fetching users:", error);
              }
        };

        /*
        const handleSelectedUser = async (user) => {
          setSelectedUser(user);

          const filteredDataset = usersinfo.filter(item => item.User.id === user.id);

          //console.log(filteredDataset)

          setSelectedUserInfo(filteredDataset[0])

          console.log(user.id)

          try {
            const response = await API.graphql(graphqlOperation(userCheckListItemsByUserId, {userId: user.id}));
            console.log(response)
            const userData = response.data.userCheckListItemsByUserId;

            const filteredItems = userData.items.filter(item => item._deleted !== true);

            const filteredDataSet1 = checklistItems.filter(item => {
              const matchingItem = filteredItems.find(data => data.checkListItemsId === item.id);
              return matchingItem !== undefined;
            });

            const checkListItems = filteredDataSet1 || [];

            setSelectedItems(checkListItems);

            //setSelectedItems(checkListItems);
          } catch (error) {
            console.log("Error fetching user data:", error);
          }

        };
        */

        const fetchUserInfo = async () => {

            try {
              // Query all users with nutrition_coaching = true

              const response = await API.graphql(graphqlOperation(getAllUserInfo));


              const userInfo = response.data.listUserInfos.items;
              console.log(userInfo)

              const filteredDataset = userInfo.filter(item => 
                item.User && item.User.nutrition_coaching === true && !item._deleted
              );

              setUsersInfo(filteredDataset)

            } catch (error) {
              console.log('Error fetching users with nutrition coaching:', error);
            }

          };

        const filteredUsers = users.filter(
          (user) =>
            (user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())) &&
            !user._deleted
        );
    
        const handleSave = async (e) => {
          e.preventDefault();
    
          try {

            const variables = {
              input: {
                id: selectedUserInfo.id,
                goal_protein: editedprotein,
                goal_carb: editedcarbs,
                goal_fat: editedfat,
                goal_fiber: editedfiber,
                updatedAt: new Date().toISOString(),
                _version: selectedUserInfo._version,
                userInfoUserId: selectedUser.id
              }
            };

    
            const response = await API.graphql(
              graphqlOperation(updateUserInfo, variables )
            );

            console.log(response)

            setSelectedUser(response.data.updateUserInfo.User);
            setSelectedUserInfo(response.data.updateUserInfo);

            // Fetch the existing user checkListItems records
            const fetchUserCheckListItemsResponse = await API.graphql(
              graphqlOperation(userCheckListItemsByUserId, { userId: selectedUser.id })
            );

            console.log('item1: ' + JSON.stringify(fetchUserCheckListItemsResponse))

            const existingItems = fetchUserCheckListItemsResponse.data.userCheckListItemsByUserId.items.filter(item => item._deleted !== true);

            console.log('item2: ' + JSON.stringify(existingItems))

            // Compare the fetched records with the selectedItems array
            const existingItemIds = existingItems.map((item) => item.checkListItemsId);

            console.log('item3: ' + JSON.stringify(existingItemIds))
            

            const newSelectedItems = selectedItems.filter(
              (item) => !existingItemIds.includes(item.id)
            );

            console.log('item4: ' + JSON.stringify(newSelectedItems))


            // Save only the filtered items to the database
            const selectedItemsData = newSelectedItems.map((item) => ({
              checkListItemsId: item.id,
              userId: selectedUser.id,
              _version: 1,
            }));

            console.log('item5: ' + JSON.stringify(selectedItemsData))



            // Compare the fetched records with the selectedItems array
            const itemsToDelete = existingItems.filter(
              (item) => !selectedItems.some((selectedItem) => selectedItem.id === item.checkListItemsId)
            );

            console.log('item6: ' + JSON.stringify(itemsToDelete))


              //Delete
              await Promise.all(
                itemsToDelete.map(async (item) => {
                  const variables = {
                    input: {
                      id: item.id,
                      _version: item._version
                    },
                  };
            
                await API.graphql(
                  graphqlOperation(deleteUserCheckListItems, variables)
                );
              })
            );


            
            if (selectedItemsData.length > 0) {
              //Add
              const responseSave = await Promise.all(
                selectedItemsData.map((itemData) =>
                  API.graphql(
                    graphqlOperation(createUserCheckListItems, { input: itemData })
                  )
                )
              );

              console.log('item7: ' + JSON.stringify(responseSave))
            }

           
    
            // Show the modal
            setShowAlert(true);
            setAlertTitle("User updated!")
    
            // Hide the modal after 2 seconds
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
            
          } catch (error) {
            console.log("Error updating user:", error);
          }
        };
    
        const handleCancel = (e) => {
          e.preventDefault();
          setSelectedUser(undefined);
          setSelectedUserInfo(undefined)
        };


        const handleSelectedUser = async (user) => {
          setSelectedUser(user);


          const filteredDataset = usersinfo.filter(item => item.User.id === user.id);
          setSelectedUserInfo(filteredDataset[0])

            //console.log('checkins: ' + JSON.stringify(checkins))
          
          // Set User Checkin Data
          const selected_user_checkins = checkins.filter((c) => c.User.id === user.id)

          //console.log('user checkins: ' + JSON.stringify(selected_user_checkins))

          // Set User Food Entry Data
          const selected_user_foodentries = foodentries.filter((c) => c.User.id === user.id)

          //console.log('user items: ' + JSON.stringify(selected_user_foodentries))
  

          if (selected_user_checkins.length > 0) {
            setSelectedUserCheckins(selected_user_checkins)
          }

          if (selected_user_foodentries.length > 0) {

             // Group items by date
            const groupedData = selected_user_foodentries.reduce((acc, item) => {
              const date = item.date;
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date].push(item);
              return acc;
            }, {});

            // Convert the groupedData object into an array of objects
            const sortedData = Object.entries(groupedData)
              .map(([date, items]) => ({
                date,
                items,
              }))
              .sort((a, b) => {
                // Sort in descending order based on date (assuming date is in "MM/DD/YYYY" format)
                return new Date(b.date) - new Date(a.date);
              });

              console.log('sorted data: ' + JSON.stringify(sortedData))

            setSelectedUserFoodEntries(sortedData)
          }
          
          try {
            const response = await API.graphql(graphqlOperation(userCheckListItemsByUserId, {userId: user.id}));
            console.log('Here is the data we are looking for: ' + JSON.stringify(response))

            const userData = response.data.userCheckListItemsByUserId;

            const filteredItems = userData.items.filter(item => item._deleted !== true);

            const filteredDataSet1 = checklistItems.filter(item => {
              const matchingItem = filteredItems.find(data => data.checkListItemsId === item.id);
              return matchingItem !== undefined;
            });

            const checkListItems = filteredDataSet1 || [];

            setSelectedItems(checkListItems);

          } catch (error) {
            console.log("Error fetching user data:", error);
          }


        }

        const [newItemValue, setNewItemValue] = useState('');

        const handleAddNewItem = async () => {
          if (newItemValue.trim() !== '') {

            
            try {

              const newItemKey = newItemValue.replace(/\s/g, ''); // Remove all spaces from the input

              // Save the new item to the database
              await API.graphql(
                graphqlOperation(createCheckListItems, {
                  input: { 
                    value: newItemValue,
                    key: newItemKey,
                    _version: 1
                   }
                })
              );
        
              // Fetch updated checklist items
              fetchChecklistItems();
        
              // Clear the new item input field
              setNewItemValue('');
            } catch (error) {
              console.log("Error adding new checklist item:", error);
            }
          }
        };

        return (
              <div className="users-page">
                
                {selectedUser ? (
                  <Container>
                  <Row>
                    <Form onSubmit={handleSave}>
                      <Row className='mb-3'>
                        <h2 style={{color: 'white'}}>{selectedUser.name}</h2>
                      </Row>
                      <Row className='mb-3'>
                        <Col sm={12} lg={2}>
                            <FloatingLabel
                                controlId="protein"
                                label="Protein"
                              >
                                <Form.Control required type="text" value={editedprotein} onChange={(e) => setEditedProtein(e.target.value)}/>
                            </FloatingLabel>
                        </Col>
                        <Col sm={12} lg={2}>
                              <FloatingLabel
                                controlId="carbs"
                                label="Carbs"
                              >
                                <Form.Control required type="text" value={editedcarbs} onChange={(e) => setEditedCarbs(e.target.value)}/>
                            </FloatingLabel>
                        </Col>
                        <Col sm={12} lg={2}>
                          <FloatingLabel
                                controlId="fat"
                                label="Fat"
                              >
                                <Form.Control required type="text" value={editedfat} onChange={(e) => setEditedFat(e.target.value)}/>
                            </FloatingLabel>
                        </Col>
                        <Col sm={12} lg={2}>
                        <FloatingLabel
                                controlId="fiber"
                                label="Fiber"
                              >
                                <Form.Control required type="text" value={editedfiber} onChange={(e) => setEditedFiber(e.target.value)}/>
                            </FloatingLabel>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col sm={12} lg={4}>
                          <FloatingLabel controlId="checklistItems" label="Add Checklist items">
                            <Form.Select value="" onChange={handleItemSelect}>
                              <option value="" disabled>Select an item</option>
                              {checklistItems.map((item) => (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  disabled={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
                                >
                                  {item.value}
                                </option>
                              ))}
                            </Form.Select>
                            </FloatingLabel>
                            </Col>
                            <Col sm={12} lg={3}>
                              <FloatingLabel
                                  controlId="newitem"
                                  label="Add New Item"
                                >
                                  <Form.Control type="text" value={newItemValue} onChange={(e) => setNewItemValue(e.target.value)} />
                              </FloatingLabel>
                              
                          </Col>
                          <Col sm={12} lg={2} style={{display: 'flex', alignItems: 'center'}}>
                              <Button style={{marginTop: 0, marginRight: 5}} type="button" onClick={handleAddNewItem} variant="outline-warning">Add New Item</Button>
                          </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col sm={12} lg={6}>
                            <div className='check-list-container'>
                                  {selectedItems.map((item) => (

                                    <div key={item.id} className="check-list-item">
                                      <label>{item.value}</label>
                                      <IonIcon icon={closeOutline} onClick={() => handleRemoveItem(item.id)} />
                                    </div>
                                  ))}
                            </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <Button style={{marginTop: 0, marginRight: 5}} type="submit" variant="outline-warning">Save</Button>
                          <Button style={{marginTop: 0}} onClick={handleCancel} variant="outline-warning" type="button">Cancel</Button>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                  <Row className='mt-5 pt-3 pb-3' style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'inherit', flexDirection: 'row', paddingBottom: 5, borderBottom: '0.25px solid #b0b0b0' }} onClick={() => setExpandCheckins(!expandcheckins)}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <div style={{justifyContent: 'center'}}>
                        <h3 style={{ color: 'white', margin: 0, fontWeight: '400' }}>Check-ins</h3>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <KeyboardArrowDownIcon style={{ color: 'white', fontSize: 30 }} />
                      </div>
                    </div>
                  </Row>
                  {expandcheckins ? (
                     <CheckinList checkin_list={selectedusercheckins} userview={true}/>
                  ) : (
                    <></>
                  )}
                  <Row className='mt-3 pt-3 pb-3' style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'inherit', flexDirection: 'row', paddingBottom: 5, borderBottom: '0.25px solid #b0b0b0' }} onClick={() => setExpandFoodIntake(!expandfoodintake)}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <div style={{justifyContent: 'center'}}>
                        <h3 style={{ color: 'white', margin: 0, fontWeight: '400' }}>Food Log</h3>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <KeyboardArrowDownIcon style={{ color: 'white', fontSize: 30 }} />
                      </div>
                    </div>
                  </Row>
                  {expandfoodintake ? (
                     <FoodLog list={selecteduserfoodentries}/>
                  ) : (
                    <></>
                  )}
                 
                  </Container>
                ) : (

                  <>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
                      <Col>
                          <Form.Control type="text" placeholder="Search Users" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} data-bs-theme="dark"/>
                      </Col>
                    </Row>
                    <Row>
                      <div className="users-grid">
                        {filteredUsers
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((user) => (
                            <Card key={user.id}
                                  body
                                  className="custom-card"
                                  onClick={() => handleSelectedUser(user)}>
                                <h4 className="custom-card-title">{user.name}</h4>
                            </Card>
                          ))}
                        </div>
                      </Row>
                    </>
                )}
              </div>
            );
    }

   

    
    return (

      <Container fluid="xxl" style={{paddingTop: 15, paddingBottom: 15, height: '100vh', color: 'white'}}>
        {showalert && (
              <Alert className="popup-modal" variant="success" onClose={() => setShowAlert(false)} dismissible>
                <Alert.Heading>{alerttitle}</Alert.Heading>
              </Alert>
          )}
            <Tab.Container defaultActiveKey="clients">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="clients" style={{color: 'white'}}>Clients</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="checkins" style={{color: 'white'}}>Check-ins</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="uploadresources" style={{color: 'white'}}>Upload Resources</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings" style={{color: 'white'}}>Settings</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="clients">
                  <UserList />
                </Tab.Pane>
                <Tab.Pane eventKey="checkins">
                  <h2>Check-ins</h2>
                  <CheckinList checkin_list={checkins_new} />
                </Tab.Pane>
                <Tab.Pane eventKey="uploadresources">
                  <ArticleList />
                    {/* Add the content for the "Upload" tab here */}
                </Tab.Pane>
                <Tab.Pane eventKey="settings">
                  <h3>Settings</h3>
                    {/* Add the content for the "Upload" tab here */}
                </Tab.Pane>
              </Tab.Content>
            
            
            </Tab.Container>
        </Container>
    );

}

export default Nutrition