import { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import Fuse from 'fuse.js';
import * as XLSX from 'xlsx';

//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Dropzone from 'react-dropzone';

import { createWorkouts, updateWorkouts, createSubWorkouts, updateSubWorkouts, createPrograms, deleteSubWorkouts, deleteWorkoutResults} from '../../graphql/mutations';
import {listPrograms, listWorkoutResults} from '../../graphql/queries';

import './workouts.css'


//bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import { ControlPointSharp } from '@mui/icons-material';


const listWorkoutsSubWorkouts = `
query listWorkoutSubworkouts {
  listWorkouts {
    items {
      SubWorkouts {
        items {
          id
          grouptitle
          group
          desc
          createdAt
          _deleted
          required
          resultcategory
          timecap
          updatedAt
          workoutsID
          _version
        }
      }
      _deleted
      createdAt
      date
      desc
      id
      title
      updatedAt
      legacy
      workout_type
      _version
    }
  }
}
`;

function Workouts() {

  const [workouts, setWorkouts] = useState([]);
  const [selectedType, setSelectedType] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isimporting, setIsImporting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [subworkoutCount, setSubWorkoutCount] = useState(0);
  const [mode, setMode] = useState('');

  //Alerts
  const [showalert, setShowAlert] = useState(false);
  const [alerttitle, setAlertTitle] = useState('');
 
  const [reselect, setReselect] = useState(false)
  const [reselectid, setReselectID] = useState(null)

  
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };


  /*
  useEffect(() => {
    async function fetchData() {
      try {
        //const response = await API.graphql(graphqlOperation(listWorkoutsQuery));

        const response = await API.graphql(graphqlOperation(listWorkoutsQuery, { 
            orderBy: {
              date: "desc"
            }
          }));

        console.log(response)

        const workouts = response.data.listWorkouts.items;
        setWorkouts(workouts);
        setFilteredDataSource(workouts);
      } catch (err) {
        console.log('Error fetching workouts', err);
      }
    }

    
    fetchData();
  }, []);
  */

  useEffect(() => {


    async function fetchData() {

        try {
            const response = await API.graphql(graphqlOperation(listWorkoutsSubWorkouts));

            

            if (response.data && response.data.listWorkouts) {

              const workouts = response.data.listWorkouts.items;

              // Filter out deleted workouts and their subworkouts
              const filteredWorkouts = workouts.filter((workout) => !workout._deleted)
                .map((workout) => {
                  const filteredSubWorkouts = workout.SubWorkouts.items.filter(
                    (subWorkout) => !subWorkout._deleted
                  );
                  return { ...workout, SubWorkouts: { items: filteredSubWorkouts } };
              });


              const sortedWorkouts = filteredWorkouts.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
              });

              if (reselect) {
                let temp_val = sortedWorkouts.filter(
                  (subWorkout) => subWorkout.id === reselectid
                );
                
                if (temp_val.length > 0) {
                  setSelectedWorkout(temp_val[0])
                }

                setReselect(false)
                setReselectID(null)
              }
        
              setWorkouts(sortedWorkouts);
              setFilteredDataSource(sortedWorkouts);
            }

          } catch (err) {
            console.log('Error fetching workouts', err);
          }
    }


    fetchData();
  }, [isEditing, refresh]);

  const options = {
    includeScore: true,
    keys: ['title', 'desc', 'SubWorkouts.items.group', 'SubWorkouts.items.desc'],
    threshold: 0.4,
  };


  const handleTypeChange = e => {
    setSelectedType(e.target.value);
    console.log(e.target.value)
  };

  useEffect(() => {
    searchFilterFunction(searchTerm);
  }, [selectedType]);


  const searchFilterFunction = (text) => {
    //keep the text box updated
    setSearchTerm(text);
  
    // Filter by workout type
    const filteredWorkoutsByType = workouts.filter(workout => {
      if (selectedType === 'All') {
        return true;
      }
      return workout.workout_type === selectedType;
    });
  
    // Check if searched text is not blank
    if (text) {
      const fuse = new Fuse(filteredWorkoutsByType, options);
      const results = fuse.search(text);
  
      const filteredWorkouts = results.map(result => result.item);


      setFilteredDataSource(filteredWorkouts);
    } else {
      setFilteredDataSource(filteredWorkoutsByType);
    }
  };

  const handleBackButtonClick = () => {
    //setShowForm(false); // hide the form
    setIsEditing(false);
    setSelectedWorkout(null); // reset the selected workout
  }

  // Add a new workout
  const addWorkout = (newWorkout) => {
    setWorkouts((prevState) => {
      return [...prevState, newWorkout];
    });
  };

  // Edit an existing workout
  const editWorkout = (workoutId, updatedWorkout) => {
    setWorkouts((prevState) => {
      const updatedWorkouts = prevState.map((workout) => {
        if (workout.id === workoutId) {
          return {
            ...workout,
            ...updatedWorkout,
          };
        } else {
          return workout;
        }
      });
      return updatedWorkouts;
    });
  };

  const handleSubmitForm = (event) => {


    event.preventDefault();
    const formData = new FormData(event.target);
    const workoutData = {};
    for (let [key, value] of formData.entries()) {
      if (value !== "") {
        workoutData[key] = value;
      }
    }


    const subworkouts = [];

    for (let i = 1; i <= subworkoutCount; i++) {

      const subworkoutData = {};

      for (let [key, value] of formData.entries()) {
        if (key.startsWith(`subworkout-${i}-`) && value !== "") {
          const subworkoutKey = key.split(`subworkout-${i}-`)[1];
          subworkoutData[subworkoutKey] = value;
        }
      }
      if (Object.keys(subworkoutData).length > 0) {
        subworkouts.push(subworkoutData);
      }
    }



    workoutData.subworkouts = subworkouts;
    if (mode === "add") {
      addWorkout(workoutData);
    } else if (mode === "edit") {
      editWorkout(workoutData);
    }


    handleBackButtonClick();
  };

  const [subworkouts, setSubworkouts] = useState([]);

  function WorkoutForm({ initialValues }) {

    

    //console.log('this: ' + JSON.stringify(initialValues))
    const compare = (a, b) => {
      // Compare by "group" field
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }

      // If "group" fields are equal, compare by "order" field
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }

      // If both fields are equal, maintain the original order
      return 0;
    };

  
    const [title, setTitle] = useState((initialValues && initialValues.title) || '');
    const [type, setType] = useState((initialValues && initialValues.workout_type) || 'FUNCTIONALFITNESS');
    const [desc, setDesc] = useState((initialValues && initialValues.desc) || '');
    const [version, setVersion] = useState((initialValues && initialValues._version) || '');
    const [saveAlert, setSaveAlert] = useState(false);

    const [showFormError, setShowFormError] = useState(false)

    // Convert the date string to a Date object
    const initialDate = initialValues && initialValues.date ? new Date(initialValues.date) : new Date();
    const [date, setDate] = useState(initialDate);

   

    //console.log('here we are: ' + JSON.stringify(initialValues))
    //const [subworkouts, setSubworkouts] = useState((initialValues && initialValues.SubWorkouts.items && initialValues.SubWorkouts.items.sort(compare)) || []);
    

    //Add Subworkout Modal
    const [showAddModal, setShowAddModal] = useState(false);

    const [group, setGroup] = useState('');
    const [grouptitle, setGroupTitle] = useState('');
    const [subdesc, setSubDesc] = useState('');
    const [rescat, setResCat] = useState(null);
    const [timecap, setTimeCap] = useState('');
    const [required, setRequired] = useState(false);

    const [timecap_hr, setTimeCapHr] = useState();
    const [timecap_min, setTimeCapMin] = useState();
    const [timecap_sec, setTimeCapSec] = useState();

    const [formattedworkouts, setFormattedWorkouts] = useState('');
    const [showformattedmodal, setShowFormattedModal] = useState(false);
    const textAreaRef = useRef(null);

    
      
  
    
    /*
    useEffect(() => {
      console.log('is this running?')
      // Initialize subworkouts only if there are items in initialValues
      if (initialValues && initialValues.SubWorkouts.items) {
        setSubworkouts(initialValues.SubWorkouts.items.sort(compare));
      }
    }, [initialValues]);
    */

    
    useEffect(() => {
      // Only set initial subworkouts if the state is empty
      console.log('SubWorkout lengthL ' +  subworkouts.length)
      if (subworkouts.length === 0) {
        if (initialValues && initialValues.SubWorkouts.items) {
          const initialSubworkouts = (initialValues && initialValues.SubWorkouts.items && initialValues.SubWorkouts.items.sort(compare)) || [];
          console.log(initialSubworkouts);
          setSubworkouts(initialSubworkouts);
        }
      }
    }, [initialValues, subworkouts]);
    
    
    const handleSaveSubWorkout = async (e) => {
      e.preventDefault();

      let timesaved = ''
      let temp_hour =  ''
      let temp_min = ''
      let temp_sec = ''

      if (rescat === 'TIME'){
        temp_hour = (!timecap_hr) ? '00' : timecap_hr.padStart(2, '0')
        temp_min = (!timecap_min) ? '00' : timecap_min.padStart(2, '0')
        temp_sec = (!timecap_sec) ? '00' : timecap_sec.padStart(2, '0')
        
        timesaved = temp_hour + ':' + temp_min + ':' + temp_sec
      }

      try {


        const input = {
          workoutsID: selectedWorkout.id,
          group,
          grouptitle,
          desc: subdesc,
          resultcategory: rescat === "" ? null : rescat,
          required: false,
          timecap: rescat === 'TIME' ? timesaved : null,
          _version: 1
        };

        console.log(input)
    
        const response = await API.graphql(graphqlOperation(createSubWorkouts, { input }));
        console.log('Created Sub workout:', response.data.createSubWorkouts);

        /*
        setSubworkouts((prevSubworkouts) => {
          const updatedSubworkout = response.data.createSubWorkouts;
          const updatedSubworkouts = [...prevSubworkouts, updatedSubworkout].sort(compare);
          return updatedSubworkouts;
        });
        */

        setSubworkouts((prevSubworkouts) => {
          const updatedSubworkout = response.data.createSubWorkouts;
          const updatedSubworkouts = [...prevSubworkouts, updatedSubworkout]
          console.log('Updated subworkouts:', updatedSubworkouts);
          return updatedSubworkouts.sort(compare);
        });

        //setSelectedWorkout(selectedWorkout)
      
        //console.log('subworkouts after setSubworkouts:', subworkouts);
        
        setShowAddModal(false)
        setSubDesc('')
        setResCat('WEIGHT')
        setRequired(false)

        // Show the modal
        setShowAlert(true);
        setAlertTitle("Subworkout Saved")

        // Hide the modal after 2 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);

       

      } catch (error) {
        console.log('Error saving workout:', error);
      }


    };
    
    const handleCancelSubworkout = () => {
      setShowAddModal(false)

      setGroup('')
      setGroupTitle('')
      setSubDesc('')
      setResCat('WEIGHT')
      setRequired(false)
    };

     const handleAddSubworkout = () => {
        setShowAddModal(true)
     }

     function addLineBreaks(text) {
      // Define the patterns to identify where line breaks should be added
      const patterns = [
        /\n+/g, // Preserve existing line breaks
        /(\n\n+)/g, // Preserve multiple consecutive line breaks
        /\n+(?=[A-Z]\.)/g, // Add line breaks before uppercase letters followed by a period (e.g., A., B., C.)
        /(\. )(?=[A-Z])/g, // Add line breaks after periods followed by an uppercase letter (e.g., . A, . B, . C)
        /(?<=\w\.)(?=\w)/g, // Add line breaks between consecutive words separated by a period (e.g., word1. word2)
        /(\.)(?=[^A-Za-z\s])/g, // Add line breaks after periods followed by non-alphabetic/non-space characters (e.g., .!, .?, .,)
      ];
    
      // Apply the patterns to add line breaks
      let formattedText = text;
      patterns.forEach(pattern => {
        formattedText = formattedText.replace(pattern, '\n');
      });

      console.log(formattedText)
    
      return formattedText;
    }

    const handleSaveWorkout = async (e) => {

      e.preventDefault();

      if (title === '' || desc === '') {
        setShowFormError(true);

      } else {
        setShowFormError(false);
        const temp_date = new Date(date);
        const formattedDate = temp_date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

        const formattedDesc = addLineBreaks(desc);


        try {
          const input = {
            title,
            desc: formattedDesc,
            date: formattedDate,
            workout_type: type,
            _version: mode === 'add' ? 1 : version
          };
      
          if (mode === 'add') {

            const response = await API.graphql(graphqlOperation(createWorkouts, { input }));

            console.log('Created workout:', response.data.createWorkouts);
            setVersion(response.data.createWorkouts._version)
            setSelectedWorkout(response.data.createWorkouts)
            setMode('edit')

          } else if (mode === 'edit') {
            const { id } = initialValues;
            const updateInput = { id, ...input };


            const response = await API.graphql(graphqlOperation(updateWorkouts, { input: updateInput }));
            console.log('Updated workout:', response.data.updateWorkouts);
            setVersion(response.data.updateWorkouts._version)
          }


           // Show the modal
          setShowAlert(true);
          setAlertTitle("Workout Saved")

          // Hide the modal after 2 seconds
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);

        } catch (error) {
          console.log('Error saving workout:', error);
        }
      }

      
    };

    const showSaveAlert = () => {

      
      setSaveAlert(true);
      setTimeout(() => {
        setSaveAlert(false);
      }, 1500); // Adjust the duration (in milliseconds) as needed
      
    };

    const TableDataRow = (props) => {
      const [showError, setShowError] = useState(false)

      const [currsubworkout, setCurrSubWorkout] = useState(props.value)

      const [group, setGroup] = useState(props.value.group);
      const [grouptitle, setGroupTitle] = useState(props.value.grouptitle);
      const [subdesc, setSubDesc] = useState(props.value.desc);
      const [rescat, setResCat] = useState(props.value.resultcategory);
      const [timecap, setTimeCap] = useState(props.value.timecap);
      const [required, setRequired] = useState(props.value.required);
      const [subversion, setSubVersion] = useState(props.value._version);

      /* Edit Row Modal */
     const [showEditModal, setShowEditModal] = useState(false);
     const [editRowData, setEditRowData] = useState({});

      let temp_hour =  ''
      let temp_min = ''
      let temp_sec = ''

      if (props.value.resultcategory === 'TIME' && props.value.timecap !== null) {
        if (props.value.timecap.includes(':')) {
          const [hour, minute, second] = props.value.timecap.split(':');
          temp_hour = hour
          temp_min = minute
          temp_sec = second
        }
      }



      const [timecap_hr, setTimeCapHr] = useState(temp_hour);
      const [timecap_min, setTimeCapMin] = useState(temp_min);
      const [timecap_sec, setTimeCapSec] = useState(temp_sec);

      const handleEdit = (rowData) => {
        setEditRowData(rowData);
        setShowEditModal(true);
      };

      const handleEditSave = async (e) => {
        e.preventDefault();

        setShowEditModal(false);


         let timesaved = ''

         if (rescat === 'TIME'){
           temp_hour = (timecap_hr === '') ? '00' : timecap_hr.padStart(2, '0')
           temp_min = (timecap_min === '') ? '00' : timecap_min.padStart(2, '0')
           temp_sec = (timecap_sec === '') ? '00' : timecap_sec.padStart(2, '0')
           
 
           timesaved = temp_hour + ':' + temp_min + ':' + temp_sec
         }


         
         try {

           const input = {
             group,
             grouptitle,
             desc: subdesc,
             resultcategory: rescat === "" ? null : rescat,
             required: false,
             timecap: rescat === 'TIME' ? timesaved : null,
             _version: subversion
           };
   
       
           const { id } = currsubworkout;
           const updateInput = { id, ...input };

           const response = await API.graphql(graphqlOperation(updateSubWorkouts, { input: updateInput }));
           console.log('Updated sub Workout:', response.data.updateSubWorkouts);

           const updatedSubworkout = response.data.updateSubWorkouts

           setSubworkouts((prevSubworkouts) => {
            return prevSubworkouts.map((subworkout) => {
              if (subworkout.id === updatedSubworkout.id) {
                return updatedSubworkout;
              }
              return subworkout;
            }).sort(compare);
          });

           setSubVersion(response.data.updateSubWorkouts._version)
           setTimeCap(response.data.updateSubWorkouts.timecap)
       
           //showSaveAlert();
   
         } catch (error) {
           console.log('Error saving workout:', error);
         }
        

      };
      
      const handleEditCancel = () => {
        setShowEditModal(false);
      };

      const deleteAssociatedWorkoutResults = async (subworkoutId) => {

        console.log('Subworkoutid: ' + subworkoutId)

        try {
          // Fetch all the workout results associated with the given subworkoutsID
          const fetchResultsResponse = await API.graphql(
            graphqlOperation(listWorkoutResults, {
              filter: {
                subworkoutsID: { eq: subworkoutId },
              },
            })
          );

          console.log(fetchResultsResponse)
      
          // Extract the array of workout results from the response
          const workoutResults = fetchResultsResponse.data.listWorkoutResults.items;
      
          // Extract the array of workout result IDs
          const workoutResultIds = workoutResults.map((result) => result.id);

          console.log(workoutResultIds.length)
          console.log(workoutResultIds)
          if (workoutResultIds.length > 0) {

            for (const resultId of workoutResultIds) {

              await API.graphql(
                graphqlOperation(deleteWorkoutResults, {
                  input: { id: resultId },
                })
              );

            }
    
          }
          
        } catch (error) {
          console.log('Error deleting workout results:', error);
        }
      };
      
      const deleteSubWorkout = async (rowdata) => {
        try {
          const subworkoutiddata = {
            id: rowdata.id,
            _version: rowdata._version
          };

      
          const response = await API.graphql(graphqlOperation(deleteSubWorkouts, { input: subworkoutiddata }));

          // Remove the deleted subworkout from the state
          setSubworkouts((prevSubworkouts) => prevSubworkouts.filter(subworkout => subworkout.id !== rowdata.id));
          console.log('Deleted SubWorkout:', response.data.deleteSubWorkouts);
        } catch (error) {
          console.log('Error deleting SubWorkout:', error);
        }
      };

      const handleDelete = async (rowData) => {
        // Delete associated WorkoutResults
        await deleteAssociatedWorkoutResults(rowData.id);
        
        // Delete SubWorkout
        await deleteSubWorkout(rowData);
        
        // Perform any additional actions after successful deletion
        setReselect(true)
        setReselectID(selectedWorkout.id)
        setRefresh(!refresh)
      };

      

      return(
        <tr>
              <td>{group}</td>
              <td>{grouptitle}</td>
              <td>{subdesc}</td>
              <td>{rescat}</td>
              <td>{timecap}</td>
              <td>{required ? 'Yes' : 'No'}</td>
              <td>
                <Button onClick={() => handleEdit(props.value)} variant="outline-warning" size="sm" style={{fontWeight: '600'}}>Edit</Button>
                <Button onClick={() => handleDelete(props.value)} variant="outline-danger" size="sm" style={{fontWeight: '600'}}>Delete</Button>
              </td>
              <Modal show={showEditModal} onHide={handleEditCancel} backdrop="static" backdropClassName="modal-backdrop-transparent" keyboard={false} centered data-bs-theme="dark">
              
                <Modal.Header closeButton>
                  <Modal.Title style={{color: 'white'}}>Edit Sub Workout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="group">
                      <Form.Label style={{color: 'white'}}>Group</Form.Label>
                      <Form.Control required type="text" value={group} onChange={(e) => setGroup(e.target.value)} className="shadow-outline" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="grouptitle">
                      <Form.Label style={{color: 'white'}} >Group Title</Form.Label>
                      <Form.Control required type="text" value={grouptitle} onChange={(e) => setGroupTitle(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="subdesc">
                      <Form.Label style={{color: 'white'}}>Sub Description</Form.Label>
                      <Form.Control as="textarea" rows={3} value={subdesc} onChange={(e) => setSubDesc(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="rescat">
                      <Form.Label style={{color: 'white'}}>Result Category</Form.Label>
                      <Form.Control as="select" value={rescat} onChange={(e) => setResCat(e.target.value)}>
                        <option value="">Select a value</option>
                        <option value="WEIGHT">Weight</option>
                        <option value="SETSREPS">Sets Reps</option>
                        <option value="TIME">Time</option>
                      </Form.Control>
                    </Form.Group>

                    <div style={{ display: rescat === 'TIME' ? 'flex' : 'none', alignItems: 'center' }}>
                      <Form.Group className="mb-3">
                        <Form.Label style={{color: 'white'}}>Time Cap</Form.Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Control required type="text" placeholder='00' value={timecap_hr || '00'} onChange={(e) => setTimeCapHr(e.target.value)} className="inputtime" disabled={rescat !== 'TIME'} />
                          <Form.Control required type="text" placeholder='10' value={timecap_min || '00'} onChange={(e) => setTimeCapMin(e.target.value)} className="inputtime" disabled={rescat !== 'TIME'} />
                          <Form.Control required type="text" placeholder='00' value={timecap_sec || '00'} onChange={(e) => setTimeCapSec(e.target.value)} className="inputtime" disabled={rescat !== 'TIME'} />
                        </div>
                      </Form.Group>
                    </div>

                    <Form.Group className="mb-3" controlId="required">
                      <Form.Check type="checkbox" label="Required" style={{color: 'white'}} checked={required} onChange={(e) => setRequired(e.target.checked)} />
                    </Form.Group>
                    <div style={{flexDirection:'row'}}>
                      <Button onClick={handleEditSave} variant="warning"  type="submit" style={{marginRight: 10}}>Save</Button>
                      <Button onClick={handleEditCancel} variant="warning">Cancel</Button>
                  </div>
                  </Form>
                  </Modal.Body>
                 
                
              </Modal>
        </tr>
      )
    }

    /*
    const TableDataRow = (props) => {

      const [editing, setEditing] = useState();
      const [showError, setShowError] = useState(false)

      const [currsubworkout, setCurrSubWorkout] = useState(props.value)

      const [group, setGroup] = useState(props.value.group);
      const [grouptitle, setGroupTitle] = useState(props.value.grouptitle);
      const [subdesc, setSubDesc] = useState(props.value.desc);
      const [rescat, setResCat] = useState(props.value.resultcategory);
      const [timecap, setTimeCap] = useState(props.value.timecap);
      const [required, setRequired] = useState(props.value.required);
      const [subversion, setSubVersion] = useState(props.value._version);



      let temp_hour =  ''
      let temp_min = ''
      let temp_sec = ''

      if (props.value.resultcategory === 'TIME' && props.value.timecap !== null) {
        if (props.value.timecap.includes(':')) {
          const [hour, minute, second] = props.value.timecap.split(':');
          temp_hour = hour
          temp_min = minute
          temp_sec = second
        }
      }

      const [timecap_hr, setTimeCapHr] = useState(temp_hour);
      const [timecap_min, setTimeCapMin] = useState(temp_min);
      const [timecap_sec, setTimeCapSec] = useState(temp_sec);

      

      const handleEdit = () => {
        setEditing(true);
      };
    
      const handleSave = async (e) => {
        e.preventDefault();


        if (group === '' || grouptitle === '' || subdesc === '') {
          setShowError(true);

        } else {
         setShowError(false)

        // Perform save operation
         setEditing(false);

         let timesaved = ''

         if (rescat === 'TIME'){
           temp_hour = (timecap_hr === '') ? '00' : timecap_hr.padStart(2, '0')
           temp_min = (timecap_min === '') ? '00' : timecap_min.padStart(2, '0')
           temp_sec = (timecap_sec === '') ? '00' : timecap_sec.padStart(2, '0')
           
 
           timesaved = temp_hour + ':' + temp_min + ':' + temp_sec
         }
 
         console.log(timesaved)
         
         try {
           const input = {
             group,
             grouptitle,
             desc: subdesc,
             resultcategory: rescat,
             required: false,
             timecap: rescat === 'TIME' ? timesaved : null,
             _version: subversion
           };
   
           console.log(input)
       
           const { id } = currsubworkout;
           const updateInput = { id, ...input };
 
           console.log(updateInput)
 
           const response = await API.graphql(graphqlOperation(updateSubWorkouts, { input: updateInput }));
           console.log('Updated sub Workout:', response.data.updateSubWorkouts);
           setSubVersion(response.data.updateSubWorkouts._version)
           setTimeCap(response.data.updateSubWorkouts.timecap)
       
           //showSaveAlert();
   
         } catch (error) {
           console.log('Error saving workout:', error);
         }
        }

      };
    
      const handleCancel = () => {
        setEditing(false);
      };

      //const buttonContainerClass = editing ? 'button-container-stacked' : 'button-container-inline';


      return(
        <tr>
          {editing ? (
            <>
              <td>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {showError ? (<p style={{color: 'red', marginTop: 0}}>*&nbsp;</p>) : ('')}
                  <input type="text" value={group} onChange={(e) => setGroup(e.target.value)} />
                
                </div>
              </td>
              <td>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {showError ? (<p style={{color: 'red', marginTop: 0}}>*&nbsp;</p>) : ('')}
                  <input type="text" value={grouptitle} onChange={(e) => setGroupTitle(e.target.value)} />
                </div>
              </td>
              <td>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {showError ? (<p style={{color: 'red', marginTop: 0}}>*&nbsp;</p>) : ('')}
                  <textarea rows="3" value={subdesc} onChange={(e) => setSubDesc(e.target.value)} />
                </div>
              </td>
              <td>
                <select value={rescat} onChange={(e) => setResCat(e.target.value)}>
                  <option value="WEIGHT">Weight</option>
                  <option value="SETSREPS">Sets Reps</option>
                  <option value="TIME">Time</option>
                </select>
              </td>
              <td>
                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                  <input type="text" value={timecap_hr} onChange={(e) => setTimeCapHr(e.target.value)} className='inputtime' disabled={rescat !== 'TIME'} />
                  <span style={{fontWeight: '600'}}>&nbsp;:&nbsp;</span>
                  <input type="text" value={timecap_min} onChange={(e) => setTimeCapMin(e.target.value)}  className='inputtime' disabled={rescat !== 'TIME'}/>
                  <span style={{fontWeight: '600'}}>&nbsp;:&nbsp;</span>
                  <input type="text" value={timecap_sec} onChange={(e) => setTimeCapSec(e.target.value)}  className='inputtime' disabled={rescat !== 'TIME'}/>
                </div>
              </td>
              <td>
                <input type="checkbox" checked={required} onChange={(e) => setRequired(e.target.checked)} />
              </td>
              <td>
                <div>
                  <Button onClick={handleSave} variant="warning">Save</Button>
                  <Button onClick={handleCancel} variant="warning">Cancel</Button>
                </div>
              </td>
            </>
          ) : (
            <>
              <td>{group}</td>
              <td>{grouptitle}</td>
              <td>{subdesc}</td>
              <td>{rescat}</td>
              <td>{timecap}</td>
              <td>{required ? 'Yes' : 'No'}</td>
              <td>
                <Button onClick={handleEdit} variant="outline-light" style={{fontWeight: '600'}}>Edit</Button>
              </td>
            </>
          )}
        </tr>
      )
    }
  */


      const handleCancel = () => {
        setMode('')
        setIsEditing(false)
        setSelectedWorkout(null)
        setTitle('');
        setType('');
        setDesc('');
        setDate('');
        setSubworkouts([]);
      };

      const handleGenerateLegacy = () => {

        setShowFormattedModal(true)

        try {
          const tags = '#cronusfit #rltw #besomebody #earnit #paytheman';
          const seperator = '_________________________________________________';
        
          const temp_date = new Date(date);
          const formattedDate = temp_date.toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          });
        
          let output = '';
          output += formattedDate + '\n';
          output += title + '\n';
          output += '\n';
          output += seperator + '\n\n';
          output += desc + '\n\n';
          output += seperator + '\n\n';
        
          const groupedSubworkouts = subworkouts.reduce((groups, subworkout) => {
            const { grouptitle, group, desc } = subworkout;
            const groupKey = grouptitle + '|' + group; // Use a unique key to group by grouptitle and group
        
            if (!groups[groupKey]) {
              groups[groupKey] = [];
            }
        
            groups[groupKey].push(desc);
        
            return groups;
          }, {});
        
          for (const groupKey in groupedSubworkouts) {
            const [grouptitle, group] = groupKey.split('|');
            const subworkoutDescs = groupedSubworkouts[groupKey];
        
            output += group + '. ' + grouptitle + '\n';
            output += subworkoutDescs.join('\n') + '\n';
            output += '\n';
          }
        
          output += seperator + '\n';
          output += tags;
        
          setFormattedWorkouts(output)
        } catch (e) {
          console.Error('Error: ' + e)
        }

       
      };
      
      const handleCopyClick = () => {

        try{
          if (textAreaRef.current) {
            navigator.clipboard.writeText(textAreaRef.current.value)
              .then(() => {
                // Optionally, show a tooltip or notification that the text is copied
                console.log('Text copied to clipboard successfully!');
              })
              .catch((error) => {
                // Handle the error if the copy operation fails
                console.error('Failed to copy text: ', error);
              });
          }
        } catch (e) {
          console.Error('Error: ', e)
        }
        
      };

      const handleCancelSquarespaceFormatter = () => {
          setShowFormattedModal(false)
      }

      return (
        <>
          <Row className="mb-3">
            <Col>
              <h2>{selectedWorkout ? 'Edit Workout' : 'Add Workout'}</h2>
            </Col>
            <Col className="d-flex justify-content-end">
                <div style={{paddingRight: 3, paddingLeft: 3}}>
                  <Button onClick={handleGenerateLegacy} disabled={selectedWorkout === null} variant="outline-warning">
                    Generate Squarespace Format
                  </Button>
                </div>
                {selectedWorkout && selectedWorkout.legacy ? (
                  <></>
                ) : (
                  <div style={{paddingRight: 3, paddingLeft: 3}}>
                    <Button onClick={handleAddSubworkout} disabled={selectedWorkout === null} variant="outline-warning">
                      Add Sub Workout
                    </Button>
                  </div>
                )}
            </Col>
          </Row>
          <Row>
            <Col md={5}> 
              <Container className="remove-container-padding">
                <Form onSubmit={handleSaveWorkout}>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel
                          controlId="titleFloatingInput"
                          label="Title"
                        >
                          <Form.Control required type="text" placeholder="Search Workouts" value={title} onChange={(e) => setTitle(e.target.value)}/>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel controlId="floatingSelect" label="Type">
                        <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
                          <option value="FUNCTIONALFITNESS">Functional Fitness</option>
                          <option value="MILITARYPREP">Military Prep</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                            controlId="dateFloatingInput"
                            label="Date"
                      >
                        <Form.Control
                          required
                          type="date" 
                          value={date.toISOString().split('T')[0]} 
                          onChange={(e) => setDate(new Date(e.target.value))}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel controlId="descFloatingInput" label="Description">
                        <Form.Control
                          required
                          as="textarea"
                          value={desc} 
                          onChange={(e) => setDesc(e.target.value)}
                          placeholder="description"
                          style={{ minHeight: '150px' }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} lg={6}>
                      <Button style={{marginTop: 0, marginRight: 5}} type="submit" variant="warning">Save</Button>
                      <Button style={{marginTop: 0}} onClick={handleCancel} variant="warning" type="button">Cancel</Button>
                    </Col>
                  </Row>
                </Form>

              </Container>
            </Col>
            <Col md={7} className="scrollable-content">
              { selectedWorkout && selectedWorkout.legacy ? (
                <div>
                  <h4 style={{color: 'white'}}>Legacy Workouts, No Sub Workout Items</h4>
                </div>
              ): (
                <div className="table-container">
                <Table striped bordered hover variant="dark" responsive>
                  <thead>
                    <tr>
                      <th>Group</th>
                      <th>Group Title</th>
                      <th>Description</th>
                      <th>Result Category</th>
                      <th>Timecap</th>
                      <th>Required</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {subworkouts.map((subworkout) => (
                      <TableDataRow key={subworkout.id} value={subworkout} />
                    ))}
                  </tbody>
                </Table>
              </div>
              )}
              

            </Col>
          </Row>
          <div>
              
              {saveAlert && (
                <div className="popup-modal">
                  <div className="popup-content">Workout Saved!</div>
                </div>
              )}

                {/* subWorkout modal */}
              <Modal show={showAddModal} onHide={handleCancelSubworkout} backdrop="static" backdropClassName="modal-backdrop-transparent" keyboard={false} centered data-bs-theme="dark">
                <Modal.Header closeButton>
                  <Modal.Title style={{color: 'white'}}>Add Sub Workout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="group">
                      <Form.Label style={{color: 'white'}}>Group</Form.Label>
                      <Form.Control required type="text" value={group} onChange={(e) => setGroup(e.target.value)} className="shadow-outline" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="grouptitle">
                      <Form.Label style={{color: 'white'}}>Group Title</Form.Label>
                      <Form.Control required type="text" value={grouptitle} onChange={(e) => setGroupTitle(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="subdesc">
                      <Form.Label style={{color: 'white'}}>Sub Description</Form.Label>
                      <Form.Control as="textarea" rows={3} value={subdesc} onChange={(e) => setSubDesc(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="rescat">
                      <Form.Label style={{color: 'white'}}>Result Category</Form.Label>
                      <Form.Control as="select" value={rescat} onChange={(e) => setResCat(e.target.value)}>
                        <option value="WEIGHT">Weight</option>
                        <option value="SETSREPS">Sets Reps</option>
                        <option value="TIME">Time</option>
                      </Form.Control>
                    </Form.Group>

                    <div style={{ display: rescat === 'TIME' ? 'flex' : 'none', alignItems: 'center' }}>
                      <Form.Group className="mb-3">
                        <Form.Label style={{color: 'white'}}>Time Cap</Form.Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Control required type="text" value={timecap_hr} onChange={(e) => setTimeCapHr(e.target.value)} className="inputtime" disabled={rescat !== 'TIME'} />
                          <Form.Control required type="text" value={timecap_min} onChange={(e) => setTimeCapMin(e.target.value)} className="inputtime" disabled={rescat !== 'TIME'} />
                          <Form.Control required type="text" value={timecap_sec} onChange={(e) => setTimeCapSec(e.target.value)} className="inputtime" disabled={rescat !== 'TIME'} />
                        </div>
                      </Form.Group>
                    </div>

                    <Form.Group className="mb-3" controlId="required">
                      <Form.Check type="checkbox" label="Required" style={{color: 'white'}} checked={required} onChange={(e) => setRequired(e.target.checked)} />
                    </Form.Group>
                  </Form>
                  <div style={{flexDirection:'row'}}>
                      <Button onClick={handleSaveSubWorkout} variant="warning" style={{marginRight: 10}}>Save</Button>
                      <Button onClick={handleCancelSubworkout} variant="warning">Cancel</Button>
                  </div>
                </Modal.Body>

              </Modal>
              
              {/* formatter modal */}
              <Modal show={showformattedmodal} onHide={handleCancelSquarespaceFormatter} backdrop="static" backdropClassName="modal-backdrop-transparent" keyboard={false} centered data-bs-theme="dark">
                <Modal.Header closeButton>
                  <Modal.Title style={{color: 'white'}}>Squarespace Format</Modal.Title>
                  
                </Modal.Header>
                <Modal.Body>
                  <Row className='mb-2'>
                    <Col>
                      <Button onClick={handleCopyClick} variant="outline-warning" className="w-100">
                        Copy
                      </Button>
                    </Col>
                  </Row>
                  <Form>
                    <FloatingLabel controlId="descFloatingInput" label="Squarespace Format">
                      <Form.Control
                        ref={textAreaRef}
                        as="textarea"
                        value={formattedworkouts} 
                        style={{ minHeight: '400px' }}
                      />
                    </FloatingLabel>
                  </Form>
                </Modal.Body>
              </Modal>

          </div>

          
          </>
      );
  };

  const handleImportBack = () => {

    setIsImporting(false)

  }




  const ImportScreen = () => {

    const UploadBox = () => {
      const [file, setFile] = useState(null);
      



      function cleanJson(jsonData) {

        // Calculate the number of columns (assuming all objects have the same structure)
        const numColumns = Object.keys(jsonData[0]).length;

        // Initialize the result array with empty arrays
        const resultArray = Array.from({ length: numColumns }, () => []);

        try{

          // Loop through the input data
          jsonData.forEach((item) => {
            for (let i = 1; i <= numColumns; i++) {
              const columnName = `column${i}`;
              resultArray[i - 1].push({ [columnName]: item[columnName] });
            }
          });

        } catch (e) {
          console.error('Clean JSON Error: ', e)
        }
       

        return resultArray;

      }

      function hasSubworkouts(obj) {
        return obj.hasOwnProperty("subworkouts") && Array.isArray(obj.subworkouts);
      }

      function hasItems(obj) {
        return obj.hasOwnProperty("items") && Array.isArray(obj.items);
      }


      function labelJson(jsonData) {

        const resArray = [];
        let isFirstrow = true;
        let trackeddate = null;
        let subworkoutobj = {};
        
        try{
          
          //Loop over json Data and organize
          jsonData.forEach((innerArray) => {
            const thisObj = {};
            let isFirstItem = true;
            let count = 0;

            

            innerArray.forEach((item) => {
              // Get the column name (e.g., "column1")
              const columnName = Object.keys(item)[0];
              
        
              // Check if this is the first item in the inner array
              if (isFirstItem) {
            

                if (isFirstrow) {
                  // Parse the input string into a Date object
                  const inputDate = new Date(item[columnName]);

                  // Check if the parsed date is invalid
                  if (isNaN(inputDate.getTime())) {
                    return "Invalid Date";
                  }

                  //Set the date
                  trackeddate = new Date(item[columnName])

                  //Push to object
                  thisObj.title = trackeddate

                  isFirstrow = false
                } else {


                  const inputDate = new Date(trackeddate);

                  // Add one day
                  inputDate.setDate(inputDate.getDate() + 1);

                  //Set new tracked date
                  trackeddate = inputDate

                  //push to object
                  thisObj.title = trackeddate
                }

                isFirstItem = false;
              } else {
                // Append the value to the existing value with a line break
                const curr_value = item[columnName]

                if (curr_value) {

                  //Check if we are on the 2nd row
                  if (count == 1) {
                    thisObj.desc = curr_value; //add desc

                  } else {
                    
                    //Check if the subworkouts array exists
                    if (!hasSubworkouts(thisObj)) {
                      thisObj.subworkouts = []; // Add an empty subworkouts array
                    }

                    //check if we are starting a new sub-workout
                    const pattern = /^[A-Z]\./;

                    if (pattern.test(curr_value)) {

                      //push and clear
                      if (Object.keys(subworkoutobj).length !== 0) {
                        thisObj.subworkouts.push(subworkoutobj)
                        subworkoutobj = {}
                      }
                      
                      //Split Title into two parts
                      //Ex. "A. Strength" => ["A", "Strength"]
                      const parts = curr_value.split('. ');

                      if (parts.length === 2) {

                        const firstPart = parts[0].toString().replace(/\s/g, '');
                        const secondPart = parts[1].toString().replace(/\s/g, '');

                        //Add the title
                        subworkoutobj.subtitle = firstPart
                        subworkoutobj.subgroup = secondPart

                      } else {
                        console.error("Invalid input format");
                      }

                      
                      
                    } else {


                      //Add the workout row
                      //subworkoutobj.item += `\n${curr_value}`

                      //Split These Values into items with details. 
                      /*

                        Test Strings:
                        "Run 3x1 mile, rest 4:00 | time 00:30:00"
                        "15 AMRAP\n5-10-15... wall ball (20/14)\n12/9 cal AAB | setsreps"
                        "Weighted box step up 3x10 ea leg | weight"

                      */

                      //Check if the items array exists
                      if (!hasItems(subworkoutobj)) {
                        subworkoutobj.items = []; // Add an empty items array
                      }

                      //Split the string on | (Should be an even number of items (or 1) if setup correctly)
                      const parts = curr_value.split('|');

                      //Check for an even number of parts or the length to = 1
                      if ((parts.length % 2) === 0 || parts.length === 1) {

                        if (parts.length === 1) {
                          //Add item to item array without a weight or 
                          subworkoutobj.items.push(
                            {
                              desc: parts[0],
                              rescategory: null,
                              time: null
                            }
                          )

                        } else {
                          //Loop over every other item to get their values

                          
                          for (var i = 0; i < parts.length - 1; i+=2) {

                            //Trim
                            const trimmed_val = parts[i + 1].toString().trim();

                            //toLower
                            const lowerCaseString = trimmed_val.toLowerCase();

                            let rescat = null, timeval = null

                            if (lowerCaseString.includes("time")) {
                              
                              const rescategory_parts = lowerCaseString.split(' ');
                              rescat = rescategory_parts[0]
                              timeval = rescategory_parts[1]

                            } else {
                              rescat = lowerCaseString
                            }
                          

                            subworkoutobj.items.push(
                              {
                                desc: parts[i],
                                rescategory: rescat,
                                time: timeval
                              }
                            )
                            
                          }
                          
                        }

                      }

                    }
                  }
                }
                
              }

              count = count + 1;
            });

            if (Object.keys(subworkoutobj).length !== 0) {

              //Push left over item from last row
              thisObj.subworkouts.push(subworkoutobj)
              subworkoutobj = {}

            }
        
            resArray.push(thisObj);

            
          });

        } catch (e) {
          console.error('Error Process labelJson: ' + e)
        }
      
        return resArray;

      }
      
      async function importWorkouts(jsonData) {


        for (const workout of jsonData) {

          
          try {

            const { title, desc } = workout;

            //format date
            const date = new Date(title);
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because months are zero-based
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();

            const formattedDate = `${month}/${day}/${year}`;

            // Create the workout record
            /*
            const createWorkoutInput = { title: formattedDate.toString(), 
                                         desc,
                                         date: formattedDate.toString(),
                                         workout_type: "FUNCTIONALFITNESS",
                                         _version: 1};

            const createWorkoutResponse = await API.graphql(graphqlOperation(createWorkouts, { input: createWorkoutInput }));
            const workoutsID = createWorkoutResponse.data.createWorkouts.id;

            console.log('Adding workout: ' )
      
            // Create subworkouts and items
            for (const subworkout of workout.subworkouts) {
              const { subtitle, subgroup, items } = subworkout;
              
              // Create the items
              for (const item of items) {
                const { desc, rescategory, time } = item;

                console.log('result category: ' + rescategory)
                
                const createSubWorkoutInput = {
                  workoutsID: workoutsID,
                  group: subtitle,
                  grouptitle: subgroup,
                  desc: desc,
                  resultcategory: rescategory === null ? null : rescategory.toUpperCase(),
                  required: false,
                  timecap: rescategory === 'time' ? time : null,
                  _version: 1
                };

                const createSubWorkoutResponse = await API.graphql(graphqlOperation(createSubWorkouts, { input: createSubWorkoutInput }));
                const subworkoutsID = createSubWorkoutResponse.data.createSubWorkouts.id;
                
                console.log('submitting subworkoutid: ' + subworkoutsID)
              }
            }
            */
          } catch (error) {
            console.error('Error importing data:', error);
          }
        }

        console.log('Workouts Imported')

      }
      
      const [isLoading, setIsLoading] = useState(false);
  
      const handleUploadClick = async (e) => {
        e.preventDefault();

        setIsLoading(true)
    
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const jsonContent = event.target.result;

            try {
              const jsonData = JSON.parse(jsonContent);
           
              //Clean Data and Organize
              console.log('cleaning JSON')
              const cleanedJson = cleanJson(jsonData)

              //Label Data
              console.log('Labeling JSON')
              const labeledJson = labelJson(cleanedJson)

              //Import Workouts
              console.log('importing data')
              //importWorkouts(labeledJson)
              importWorkouts(labeledJson)
                .then(() => {
                  // Data processing is complete, hide the loading screen
                  setIsLoading(false)
                })
                .catch((error) => {
                  console.error('Error importing data:', error);
                  // Handle errors and hide the loading screen if necessary
                  setIsLoading(false);
                });

            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          };
    
          reader.readAsText(file);
        }
      };

      const Loading = () => (
        <div id="loading-screen">
          <div className="spinner"></div>
          <p>Importing Data Please Wait...</p>
        </div>
      );
  

      const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };
    
      return (
        <Form >
          <Row>
            <Col sm={12} lg={6}>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Control required type="file" onChange={handleFileChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              {isLoading ? (
                <Loading />
              ) : (
                <Button style={{marginTop: 0, marginRight: 5}} onClick={(e) => handleUploadClick(e)} variant="warning" type="submit">Import</Button>
              )}
              
            </Col>
          </Row>
        </Form>
      );
    };



    return (
      <Container>
        <Row>
          <Col>
            <Button style={{marginTop: 0, marginRight: 5}} onClick={() => handleImportBack()} variant="outline-warning" type="button">Back</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 style={{color: 'white'}}>Import Workouts</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <UploadBox />
          </Col>
        </Row>
      </Container>
    )
  }
  
  const ProgramsList = () => {
    const [programs, setPrograms] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedArticle, setSelectedProgram] = useState(undefined);

    const [showUploadContainer, setShowUploadContainer] = useState(false);


    useEffect(() => {
      fetchPrograms();
    }, []);

    const fetchPrograms = async () => {
      try {
          const response = await API.graphql(graphqlOperation(listPrograms, {
            filter: {
              data_type: {
                eq: 'pdf'
              }
            }
          }));


          const programslist = response.data.listPrograms.items;


          const filteredList = programslist.filter((e) => !e._deleted)

          setPrograms(filteredList);

        } catch (error) {
          console.log("Error fetching users:", error);
        }
    };

    const handleSelectedProgram = (program) => {

      setSelectedProgram(program)

      console.log(program)

    }

    const filteredPrograms = programs.filter(
      (program) =>
        program.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        program.desc.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleToggleUploadContainer = () => {
      setShowUploadContainer(!showUploadContainer);
    };

    const UploadBox = () => {
      const [file, setFile] = useState(null);
      const [title, setTitle] = useState('');
      const [description, setDescription] = useState('');
      const [paid, setPaid] = useState(true);
      const [price, setPrice] = useState('');
      const [radioValue, setRadioValue] = useState('Free');
  
      const radios = [
        { name: 'Free', value: 'Free' },
        { name: 'Paid', value: 'Paid' },
      ];
  
      const handleFileUpload = async (event) => {
        
        event.preventDefault();

        if (!file || !title || !description) {
          // Handle missing file, title, or description
          return;
        }
    
        try {
          // Check if the selected file is a PDF
          if (file.type === 'application/pdf') {
            // Generate a unique filename for the uploaded file
            const path = 'programs/';
            const fileName = `${path}${Date.now()}-${file.name}`;
    
            // Upload the file to S3
            await Storage.put(fileName, file, {
              contentType: file.type,
            });
            
            // Insert the file information into the Articles table
            const programData = {
              title,
              desc: description,
              free: paid,
              price: !paid ? price : 0,
              downloadurl: fileName,
              data_type: 'pdf',
              _version: 1
            };
  
            console.log(programData)
  
            await API.graphql(graphqlOperation(createPrograms, { input: programData }));
  
            console.log('File uploaded successfully');
  
            setShowUploadContainer(false)

            //Trigger
            setIsEditing(!isEditing)
            setIsEditing(!isEditing)
  
            // Perform any additional logic after successful upload
          } else {
            throw new Error('Invalid file type. Please select a PDF file.');
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle the error appropriately
        }
      };
  
      const handleRadioButton = (value) => {
  
        setRadioValue(value)
  
        if (value === 'Free') {
          setPaid(true)
        } else if (value === 'Paid') {
          setPaid(false)
        }
      }
  
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
      }
    
      return (
        <Form>
        <Row>
          <Col sm={12} lg={6}>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Control required type="file" onChange={handleFileChange} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
              <Col sm={12} lg={6}>
                <FloatingLabel controlId="titleFloatingInput" label="Title" className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          <Row>
          <Col sm={6} lg={3} >
            <ButtonGroup className="d-flex align-items-center justify-content-center">
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={idx % 2 ? 'outline-danger' : 'outline-success'}
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => handleRadioButton(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            {/*
              <Form.Group className="mb-3 d-flex align-items-center justify-content-center">
                <Form.Check
                  type="checkbox"
                  checked={paid}
                  onChange={(e) => setPaid(e.target.checked)}
                  id="checkPaidInput"
                  label="Paid?"
                />
              </Form.Group>
            */}
          </Col>
          <Col sm={6} lg={3}>
            <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control required type="text" placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} disabled={paid} />
                <InputGroup.Text>.00</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
  
        <Row className='mb-3'>
          <Col sm={12} lg={6}>
            <FloatingLabel controlId="descFloatingInput" label="Program Description">
              <Form.Control
                required
                as="textarea"
                value={description}
                onChange={e => setDescription(e.target.value)}
                style={{ minHeight: '150px' }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={6}>
            <Button style={{marginTop: 0, marginRight: 5}} onClick={(e) => handleFileUpload(e)} variant="warning" type="submit">Upload</Button>
            <Button style={{marginTop: 0}} onClick={() => setShowUploadContainer(!showUploadContainer)} variant="warning" type="button">Cancel</Button>
          </Col>
        </Row>
        </Form>
      );
    };

    return(
      <Container className='p-3'>
        <Row className="g-3 mb-3">
          <Col>
            <h2>Programs</h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button onClick={handleToggleUploadContainer} variant="warning">{showUploadContainer ? 'Hide Upload Box' : 'Upload'}</Button>
          </Col>
        </Row>
        {showUploadContainer ? (
          <UploadBox />
        ) : (
          <>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
              <Col>
                  <Form.Control type="text" placeholder="Search Programs" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} data-bs-theme="dark"/>
              </Col>
            </Row>
            <Row>
              <div className="users-grid">
                {filteredPrograms
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((program) => (

                    <Card key={program.id} border="light" style={{ width: '18rem' }} className="custom-card" onClick={() => {
                      handleSelectedProgram(program);
                    }}>
                      <Card.Header style={{justifyContent: 'space-between', display: 'flex'}}>
                        <div>
                          {program.title}
                        </div>
                        <div>
                          {!program.free ? (
                            <Badge pill  bg="dark">$ {program.price}</Badge>
                          
                          ) : (
                            <Badge pill  bg="success">Free</Badge>
                          )}
                        </div>
                      </Card.Header>
                        <Card.Body>
                          <Card.Title></Card.Title>
                          <Card.Text>
                            {truncateText(program.desc, 150)} ...
                          </Card.Text>
                        </Card.Body>
                      </Card>
                  ))}
              </div>
            </Row>
          </>
        )}
        
      </Container>
    )

  };


  return (
    <Container fluid="xxl" style={{paddingTop: 15, paddingBottom: 15, height: '100vh', color: 'white'}}>
      {showalert && (
        <Alert className="popup-modal" variant="success" onClose={() => setShowAlert(false)} dismissible>
            <Alert.Heading style={{fontSize: 20, fontWeight: '400', marginBottom: 0}}>{alerttitle}</Alert.Heading>
        </Alert>
      )}
      <Tab.Container defaultActiveKey="workouts">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="workouts" style={{color: 'white'}}>Workouts</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="programs" style={{color: 'white'}}>Programs</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="workouts">
          <Container fluid className='p-3'>

          { isEditing ? (
              <WorkoutForm
                initialValues={selectedWorkout}
              />
          ) : (

            isimporting ? (
             <ImportScreen />
          ) : (
            <>
              <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
                <Col>
                  <h2 >Workouts</h2>
                </Col>
                <Col>
                  <Button onClick={() => {
                      setIsEditing(true);
                      setMode('add')
                  }} 
                  variant="outline-warning">Add Workout</Button>
                  
                </Col>
                <Col>
                  <Button onClick={() => {
                      setIsImporting(true)
                  }} 
                  variant="outline-warning">Import Workouts</Button>
                  
                </Col>
                <Col>
                  <Form.Control type="text" placeholder="Search Workouts" value={searchTerm} onChange={e => searchFilterFunction(e.target.value)}/>
                </Col>
                <Col>
                  <Form.Select aria-label="Type:" id="type" value={selectedType} onChange={handleTypeChange}>
                    <option value="All">All</option>
                    <option value="FUNCTIONALFITNESS">Functional Fitness</option>
                    <option value="MILITARYPREP">Military Prep</option>
                  </Form.Select>
                </Col>
                </Row>
                <Row>
                  <div className="overflow-grid">
                  {filteredDataSource.map((workout) => (
                    
                    <Card key={workout.id} border="light" style={{ width: '18rem' }} className="custom-card" onClick={() => {
                            setIsEditing(true);
                            setSelectedWorkout(workout);
                            setMode('edit');
                          }}>
                      <Card.Header style={{justifyContent: 'space-between', display: 'flex'}}>
                          <div>
                          {workout.workout_type === 'FUNCTIONALFITNESS' ? 'Functional Fitness' : 'Military Prep'} 
                          </div>
                          <div>
                        {workout.date}
                        </div>
                        </Card.Header>
                        <Card.Body>
                          <Card.Title>{workout.title}</Card.Title>
                          <Card.Text>
                            {truncateText(workout.desc, 150)} ...
                          </Card.Text>
                        </Card.Body>
                    </Card>
                  ))}
                </div>  
              </Row>
            </>
          )
         )}

      </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="programs">
            {/* Content for Programs tab */}
            <ProgramsList />
          </Tab.Pane>
        </Tab.Content>
      
      
      </Tab.Container>
    </Container>


  );
  
}

export default Workouts;