import { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

//bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { listUsers, listWaivers } from '../../graphql/queries';
import { updateWaivers, updateUser } from '../../graphql/mutations';

function Settings() {

    const [tandcedit, setTandCEdit] = useState(false)
    const [tandc, setTandC] = useState('')
    const [tandcdesc, setTandCDesc] = useState('')

    const [nutritionwaiveredit, setNutritionWaiverEdit] = useState(false)
    const [nutritionwaiver, setNutritionWaiver] = useState('')
    const [nutritionwaiverdesc, setNutritionWaiverDesc] = useState('')

    const [waivers, setWaivers] = useState([]);
    const [showalert, setShowAlert] = useState(false);
    const [alerttitle, setAlertTitle] = useState('');
    const [showwarning, setShowWarning] = useState('');

    useEffect(() => {
        const fetchWaivers = async () => {
          try {
            const response = await API.graphql({
              query: listWaivers,
            });
    
            const data = response.data.listWaivers.items;
            setWaivers(data);

            if (data.length > 0){

                const tandcdata = data.filter(e => e.key === 'TermsAndConditions')
                setTandC(tandcdata[0])
                setTandCDesc(tandcdata[0].desc)

                const nutritionwaiverdata = data.filter(e => e.key === 'NutritionWaiver')
                setNutritionWaiver(nutritionwaiverdata[0])
                setNutritionWaiverDesc(nutritionwaiverdata[0].desc)

            }

          } catch (error) {
            console.error('Error fetching waivers:', error);
          }
        };
    
        fetchWaivers();
    }, []);

    const updateOverlappingUserTimeStamps = async (waiverkey, timestamp) => {


        try {
        
            const result = await API.graphql(
              graphqlOperation(listUsers)
            );
        
            const users = result.data.listUsers.items;

            const filtered_users = users.filter((user) =>
                user.termsandconditions > timestamp
            );


            const sixDaysInMillis = 6 * 24 * 60 * 60 * 1000;
            console.log(filtered_users)
            

            //Loop over users and move their timestamp by 6 days
            if (filtered_users.length > 0) {

                for (const curr_user of filtered_users) {

                    const adjustedtermsandconditions = curr_user.termsandconditions - sixDaysInMillis

                    console.log(adjustedtermsandconditions)

                    const input = {
                        id: curr_user.id,
                        termsandconditions: adjustedtermsandconditions,
                        _version: curr_user._version
                    };
                
                    const result = await API.graphql(
                        graphqlOperation(updateUser, { input })
                    );
                
                    const updatedUser = result.data.updateUser;
                    console.log(filtered_users)
        
                }
            }



          } catch (error) {
            console.error('Error fetching users:', error);
            return [];
          }

        /*
        try {
          // Fetch all the workout results associated with the given subworkoutsID
          const fetchResultsResponse = await API.graphql(
            graphqlOperation(listWorkoutResults, {
              filter: {
                subworkoutsID: { eq: subworkoutId },
              },
            })
          );
      
          // Extract the array of workout results from the response
          const workoutResults = fetchResultsResponse.data.listWorkoutResults.items;
      
          // Extract the array of workout result IDs
          const workoutResultIds = workoutResults.map((result) => result.id);

          console.log(workoutResultIds.length)
          console.log(workoutResultIds)
          if (workoutResultIds.length > 0) {

            for (const resultId of workoutResultIds) {

              await API.graphql(
                graphqlOperation(deleteWorkoutResults, {
                  input: { id: resultId },
                })
              );

            }
    
          }
          
        } catch (error) {
          console.log('Error deleting workout results:', error);
        }
        */
      };

    const handleUpdateWaiver = async (id, key, newDesc, version) => {

        try {
            //Calculate date 5 days prior
            const new_date = new Date().getTime()

            // Calculate the number of milliseconds in 5 days
            const fiveDaysInMillis = 5 * 24 * 60 * 60 * 1000;

            // Subtract 5 days from the current date
            const fiveDaysAgoInMillis = new_date - fiveDaysInMillis;

            // Convert the result back to a Date object
            // This will eliminate timezone issues
            const fiveDaysAgoDate = new Date(fiveDaysAgoInMillis).getTime();

            const response = await API.graphql({
                query: updateWaivers,
                variables: {
                    input: {
                        id: id, // Provide the ID of the waiver you want to update
                        desc: newDesc, // Provide the new value for the desc field
                        publishedDate: fiveDaysAgoDate.toString(),
                        _version: version
                    },
                },
            });

            
            updateOverlappingUserTimeStamps(key, fiveDaysAgoDate)
            console.log(response)

            const updatedWaiver = response.data.updateWaivers;
            
            // Update the waivers state with the updated waiver
            setWaivers((prevWaivers) =>
                prevWaivers.map((waiver) => (waiver.id === updatedWaiver.id ? updatedWaiver : waiver))
            );


        } catch (error) {
            console.error('Error updating waiver:', error);
        }
    };

    const handleEditTermsAndConditionsPress = () => {
        setTandCEdit(true)

        console.log('Editing New Terms and conditions')
    }

    const handleSaveTermsAndConditionsPress = () => {
        setTandCEdit(false)

        try {
            
            handleUpdateWaiver(tandc.id, tandc.key, tandcdesc, tandc._version);
            
            // Show the modal
            setShowAlert(true);
            setAlertTitle("Terms and Conditions Updated")
    
            // Hide the modal after 2 seconds
            setTimeout(() => {
             setShowAlert(false);
            }, 2000);

        } catch (e) {
            console.error('Error: ', e)
        }
    }

    const handleSave = (key) => {

      setShowWarning(false)

      if (key === 'TermsAndConditions') { 
        setTandCEdit(false)
      } else if (key === 'NutritionWaiver') { 
        setNutritionWaiverEdit(false)
      }

      try {

        
          if (key === 'TermsAndConditions') {
            handleUpdateWaiver(tandc.id, tandc.key, tandcdesc, tandc._version);
            // Show the modal
            setShowAlert(true);
            setAlertTitle("Terms and Conditions Updated")
          } else if (key === 'NutritionWaiver') {
            handleUpdateWaiver(nutritionwaiver.id, nutritionwaiver.key, nutritionwaiverdesc, nutritionwaiver._version);
            // Show the modal
            setShowAlert(true);
            setAlertTitle("Nutrition Waiver Updated")
          }
          
          
  
          // Hide the modal after 2 seconds
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);

      } catch (e) {
          console.error('Error: ', e)
      }
      
    }

    const handleCancel = (key) => {

      setShowWarning(false)

      if (key === 'TermsAndConditions') { 
        setTandCEdit(false)
      } else if (key === 'NutritionWaiver') { 
        setNutritionWaiverEdit(false)
      }
    }

    const handleEdit = (key) => {

      setShowWarning(true)

      if (key === 'TermsAndConditions') { 
        setTandCEdit(true)
      } else if (key === 'NutritionWaiver') { 
        setNutritionWaiverEdit(true)
      }
      
    }

    
    return (

        <Container fluid="xxl" style={{paddingTop: 15, paddingBottom: 15, height: '100vh', color: 'white'}}>
            {showalert && (
                <Alert className="popup-modal" variant="success" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading style={{fontSize: 20, fontWeight: '400', marginBottom: 0}}>{alerttitle}</Alert.Heading>
                </Alert>
            )}
            
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
                <Col>
                  <h3>Terms and Conditions</h3>
                </Col>
            </Row>
            {showwarning && (
              <Row>
                <Col sm={12}>
                  <Alert  variant="danger" onClose={() => setShowWarning(false)} dismissible>
                      <Alert.Heading style={{fontSize: 20, fontWeight: '400', marginBottom: 0}}>Updating any of these fields will effect all users, they will have to re-accept the next time they access the application</Alert.Heading>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
                <Col sm={1}>
                    { tandcedit ? (
                      <>
                        <Button onClick={() => handleSave('TermsAndConditions')} style={{marginTop: 0, marginRight: 5, marginBottom: 5}} variant="outline-warning">Save</Button>
                        <Button onClick={() => handleCancel('TermsAndConditions')} style={{marginTop: 0, marginRight: 5, marginBottom: 5}} variant="outline-warning">Cancel</Button>
                      </>
                    ) : (
                        <Button onClick={() => handleEdit('TermsAndConditions')} style={{marginTop: 0, marginRight: 5}} variant="outline-warning">Edit</Button>
                    )}
                    
                </Col>
                <Col sm={11}>
                    <Row className='mb-3'>
                            <Form>
                                <FloatingLabel controlId="descFloatingInput" label="Terms and Conditions">
                                <Form.Control
                                    required
                                    as="textarea"
                                    value={tandcdesc}
                                    onChange={e => setTandCDesc(e.target.value)}
                                    style={{ minHeight: '150px' }}
                                    disabled={!tandcedit}
                                    className="textarea-scrollable"
                                />
                                </FloatingLabel>
                            </Form>
                    </Row>
                </Col>
            </Row>

            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
                <Col>
                  <h3>Nutrition Waiver</h3>
                </Col>
            </Row>
            <Row>
                <Col sm={1}>
                    { nutritionwaiveredit ? (
                      <>
                        <Button onClick={() => handleSave('NutritionWaiver')} style={{marginTop: 0, marginRight: 5, marginBottom: 5}} variant="outline-warning">Save</Button>
                        <Button onClick={() => handleCancel('NutritionWaiver')} style={{marginTop: 0, marginRight: 5, marginBottom: 5}} variant="outline-warning">Cancel</Button>
                      </>
                    ) : (
                        <Button onClick={() => handleEdit('NutritionWaiver')} style={{marginTop: 0, marginRight: 5}} variant="outline-warning">Edit</Button>
                    )}
                    
                </Col>
                <Col sm={11}>
                    <Row className='mb-3'>
                            <Form>
                                <FloatingLabel controlId="descFloatingInput" label="Nutrition Waiver">
                                <Form.Control
                                    required
                                    as="textarea"
                                    value={nutritionwaiverdesc}
                                    onChange={e => setNutritionWaiverDesc(e.target.value)}
                                    style={{ height: '150px' }}
                                    disabled={!nutritionwaiveredit}
                                    className="textarea-scrollable"
                                />
                                </FloatingLabel>
                            </Form>
                    </Row>
                </Col>
            </Row>
      
      
        </Container>

    );

}
export default Settings