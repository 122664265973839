import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//scss
import './assets/styles/app.css';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';


import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter } from "react-router-dom"

import { Amplify } from 'aws-amplify';
import config from './aws-exports';

Amplify.configure({
  ...config,
  Analytics: {
   disabled: true,
  },
 });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
