import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listUsers, getUser, listMessages  } from '../../graphql/queries';
import { deleteUser, updateUser} from '../../graphql/mutations';

//bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import { TroubleshootOutlined } from '@mui/icons-material';


function Dashboard() {

  const [userdeletes, setUserDeletes] = useState([])
  const [refresh, setRefresh] = useState(false)


  useEffect(() => {

    //Get the delete requested users list
    getRequestedToDeleteUsers();

   
  }, [refresh]);


  const getRequestedToDeleteUsers = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(listUsers, {
          filter: {
            requestdelete: { eq: true },
            _deleted: {ne: true}
          },
        })
      );

      const listofusers = response.data.listUsers.items

      setUserDeletes(listofusers)
    
    } catch (e) {
      console.log('Error: ', e)
    }
  }


  const TableDataRow = (props) => {

    const [userid, setuserid] = useState(props.user.id);
    const [version, setVersion] = useState(props.user._version);
    const [email, setEmail] = useState(props.user.email);
    const [name, setName] = useState(props.user.name);
    const [sub, setSub] = useState(props.user.sub);

    console.log(userid, email, name, sub)

    const handleCancel = () => {
      if (userid) {
        updateUserRequestDeleteStatus(userid)
      }
    }

    const handleApprove = () => {
      if (userid) {
        deleteUserById(userid)
      }
    }

    const deleteUserById = async (userId) => {
      try {
        const input = {
          id: userId,
          _version: version
        };

        /*
    
        await API.graphql(graphqlOperation(deleteUser, { input }));
        */
    
        console.log('User deleted successfully.');

        if (sub) {
          deleteUserFromCognito(sub)
        }
        
        setRefresh(!refresh)
      } catch (error) {
        console.error('Error deleting user:', error);
        // Handle the error, show a message to the user, etc.
      }
    };

    const deleteUserFromCognito = async (cognitoUsername) => {
      try {
        console.log(cognitoUsername)
        //await Auth.deleteUser(cognitoUsername);
        console.log('User deleted from Cognito successfully.');
      } catch (error) {
        console.error('Error deleting user from Cognito:', error);
        // Handle the error, show a message to the user, etc.
      }
    };

    const updateUserRequestDeleteStatus = async (userId) => {
      try {

        const input = {
          id: userId,
          requestdelete: false,
          _version: version,
          updatedAt: new Date().toISOString()
        };
    
        await API.graphql(graphqlOperation(updateUser, { input }));
    
        console.log('User requestdelete status updated successfully.');
        setRefresh(!refresh)
      } catch (error) {
        console.error('Error updating user requestdelete status:', error);
        // Handle the error, show a message to the user, etc.
      }
    };

    return(
      <tr>
            <td>{email}</td>
            <td>{name}</td>
            <td>
              <Button onClick={() => handleApprove(props.value)} variant="outline-warning" size="sm" style={{fontWeight: '600'}}>Approve</Button>
              <Button onClick={() => handleCancel(props.value)} variant="outline-danger" size="sm" style={{fontWeight: '600'}}>Cancel</Button>
            </td>
      </tr>
    )
  }
    
    return (
      <Container fluid style={{ height: '100vh' }}>
        <Row style={{ height: '100%' }}>
          <Col sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', paddingLeft: 5, paddingRight: 5 }}>
            <h3 style={{color: 'white'}}>Dashboard</h3>
          </Col>
          <Col sm={12} md={6} className="scrollable-content" style={{ display: 'flex', flexDirection: 'column', paddingLeft: 5, paddingRight: 5 }}>
            <div>
              <h3 style={{color: 'white'}}>Users who have requested to delete their account</h3>
            </div>
            <div className="table-container">
              {userdeletes.length > 0 ? (
                 <Table striped bordered hover variant="dark" responsive>
                 <thead>
                   <tr>
                     <th>Email</th>
                     <th>Name</th>
                     <th></th>
                   </tr>
                 </thead>
                 <tbody>
                   {userdeletes.map((user) => (
                     <TableDataRow key={user.id} user={user} />
                   ))}
                 </tbody>
               </Table>
              ) : (
                <div style={{ display: 'flex'}}>
                  <label style={{color: 'white', textAlign: 'center'}}>There are no requests at the moment...</label>
                </div>
              )}
               
              </div>
          </Col>
        </Row>
      </Container>
    );

}

export default Dashboard


  const useStyles = makeStyles({
    container: {
      flex: 1,
      backgroundColor: '#d4d4d4',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });