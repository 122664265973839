import {useState, useEffect} from 'react';

import "@aws-amplify/ui-react/styles.css";

import {
  withAuthenticator,
  View,
  Text,
  Flex,
  ScrollView
} from "@aws-amplify/ui-react";

import { API, graphqlOperation, Auth, Hub } from 'aws-amplify';
import { listMessages, listUsers, getUser } from '../src/graphql/queries'
import { updateUser} from '../src/graphql/mutations';
import { Controller, useForm } from 'react-hook-form';
import { Route, Routes } from "react-router-dom"

//Material UI
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleIcon from '@mui/icons-material/People';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SettingsIcon from '@mui/icons-material/Settings';

//bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';


//Screens
import Dashboard from "./pages/dashboard"
import Messages from "../src/pages/messages"
import Nutrition from "../src/pages/nutrition"
import Workouts from "../src/pages/workouts"
import Users from "../src/pages/users"
import Data from "../src/pages/data"
import Settings from "../src/pages/settings"

//images
import logo_name from "./assets/images/CronusFit_Logo_Transparent.png"
import logo_admin from "./assets/images/CronusFitLogo_admin.png"
import { WorkOutlineSharp } from '@mui/icons-material';



const AWS = require('aws-sdk');
  // Configure AWS with your region (if needed)
  AWS.config.update({ region: 'us-east-1' });

function App({ signOut }) {

 

  const UnsubscribeForm = () => {
    // State to manage user input
    const [email, setEmail] = useState('');
    const [showalert, setShowAlert] = useState(false);
    const [alerttitle, setAlertTitle] = useState('');

    

    const updateUserDeleteStatus = async () => {
      try {

        const response = await API.graphql(
          graphqlOperation(listUsers)
        );

        const allusers = response.data.listUsers.items
        const filtered_user = allusers.filter((e) => e.email.toLowerCase().replace(/\s+/g, '') === email.toLowerCase().replace(/\s+/g, '') && e._deleted !== true)

        console.log(filtered_user)
        
        if (filtered_user.length === 1) {

          const account_toupdate = filtered_user[0]

          const input = {
            id: account_toupdate.id,
            requestdelete: true,
            _version: account_toupdate._version,
            updatedAt: new Date().toISOString()
          };
      
          await API.graphql(graphqlOperation(updateUser, { input }));
      
          console.log('User requestdelete status updated successfully.');

          setEmail('')

          // Show the modal
          setShowAlert(true);
          setAlertTitle("The account associated with this email will be removed");
  
          // Hide the modal after 2 seconds
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);


        }
        
      } catch (error) {
        console.error('Error updating user requestdelete status:', error);
        // Handle the error, show a message to the user, etc.
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      updateUserDeleteStatus()
      /*
       // Create a CognitoIdentityServiceProvider object
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

    // Specify the email of the user you want to delete
    const userEmail = 'user@example.com';

    // Search for the user by email across all user pools
    const listUserPoolsParams = {
      MaxResults: 10, // Specify the maximum number of user pools to retrieve
    };
    cognitoIdentityServiceProvider.listUserPools(listUserPoolsParams, (err, data) => {
      if (err) {
        console.log('Error listing user pools:', err);
      } else {
        const userPools = data.UserPools;

        // Iterate through the user pools
        userPools.forEach(userPool => {
          const userPoolId = userPool.Id;

          // Search for the user by email in this user pool
          const listUsersParams = {
            UserPoolId: userPoolId,
            Filter: `email = "${email}"`,
          };

          cognitoIdentityServiceProvider.listUsers(listUsersParams, (err, userData) => {
            if (err) {
              console.log('Error listing users:', err);
            } else {
              if (userData.Users.length > 0) {
                const usernameToDelete = userData.Users[0].Username;
                console.log(`User to delete found in user pool: ${userPool.Name}`);
                console.log('Username to delete:', usernameToDelete);

                // Now you can use the obtained usernameToDelete in your delete user API call
                const deleteUserParams = {
                  UserPoolId: userPoolId,
                  Username: usernameToDelete,
                };

                 // Delete the user
                 
                  cognitoIdentityServiceProvider.adminDeleteUser(params, (err, data) => {
                    if (err) {
                      console.log('Error deleting user:', err);
                    } else {
                      console.log('User deleted successfully:', data);
                    }
                  });
                  
              } else {
                console.log(`User not found with the provided email in user pool: ${userPool.Name}`);
              }
            }
          });
        });
      }
    });
    */
      
    }
   
    return (

      <div id="app" style={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0 }}>
          {showalert && (
            <Alert className="popup-modal" style={{ left: 50 }} variant="success" onClose={() => setShowAlert(false)} dismissible>
              <Alert.Heading style={{ fontSize: 20, fontWeight: '400', marginBottom: 0 }}>{alerttitle}</Alert.Heading>
            </Alert>
          )}
          <Container fluid className="remove-container-padding">
            <Row className="justify-content-center mb-3">
              <Col xs={12} sm={8} md={6} lg={4}>
                <h1>Unsubscribe</h1>
                <p>Enter the email associated to the account to request deletion:</p>
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              <Col xs={12} sm={8} md={6} lg={4}>
                <form onSubmit={handleSubmit}>
                  <Row className="justify-content-center mb-3">
                    <Col>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col>
                      <Button style={{ marginTop: 0, marginRight: 5 }} type="submit" variant="warning">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Container>
        </div>


    );
  };

  const AppContainer = () => {

    const [showalert, setShowAlert] = useState(false);
    const [alerttitle, setAlertTitle] = useState('');
  
    const { collapseSidebar, collapsed } = useProSidebar();
  
    const [showdashboard, setShowDashboard] = useState(true);
  
    const [nav, setNav] = useState('dashboard');
  
    const [user, setUser] = useState();
    const [userauth, setUserAuth] = useState(undefined);
    const [loading, setLoading] = useState(false);
  
    const { handleSubmit, control } = useForm();
  
    //const [activetab, setActiveTab]
  
    const handleMenuItemClick = (nav) => {
      setNav(nav);
    };
  
  
    async function fetchUser() {
      try {
        // get current authenticated user's sub
        const {
          attributes: { sub },
        } = await Auth.currentAuthenticatedUser();
  
        const authuserinfo = await Auth.currentAuthenticatedUser();
        console.log('authinfo: ' + JSON.stringify(authuserinfo))
  
        setUserAuth(sub)
  
         // query User table to get user's id based on sub value
         const userData = await API.graphql(
          graphqlOperation(listUsers, {
            filter: { sub: { eq: sub } },
          })
        );
  
        console.log('made it here')
        
        const user_temp = userData.data.listUsers.items[0];
        console.log(user_temp)
        setUser(user_temp)
  
      } catch (error) {
        console.log("Error fetching user:", error);
        setUserAuth(undefined)
      }
    }
  
    useEffect(() => {
  
      fetchUser();
  
    }, []);
  
    useEffect(() => {
  
      const listener = data => {
        if (data.payload.event === 'signIn' || data.payload.event === 'signOut') {
  
          console.log(data.payload.event)
  
          if (data.payload.event === 'signOut'){
             //DataStore.clear();
          }
  
          fetchUser();
  
        }
      };
  
      Hub.listen('auth', listener);
  
      return () => Hub.listen('auth', listener);
  
    }, []); 
  
  
    const onLogOutPress = async () => {
  
      Auth.signOut();
  };
  
  const onSignInPressed = async (data) => {
  
    if (loading){
      return;
    }
  
    setLoading(true);
  
    
    try{
  
      const response = await Auth.signIn(data.username, data.password);
      const { signInUserSession } = response;
  
      // Check if the user belongs to the "Admin" group
      const isAdmin = signInUserSession?.accessToken?.payload['cognito:groups']?.includes('Admin');
  
      if (!isAdmin) {
        Auth.signOut();
        //Alert User
        setShowAlert(true);
        setAlertTitle("Access denied. You are not authorized to log in.");
        // Hide the modal after 2 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      }
  
    } catch (e) {
      console.log('error')
        // Show the modal
        setShowAlert(true);
        setAlertTitle("Email or Password are incorrect!")
  
        // Hide the modal after 2 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
  
    }
  
  
    setLoading(false);
  
  }
  
  
    function PageController() {
      
      if (nav === 'dashboard'){
  
        return <Dashboard />;
  
      } else if (nav === 'messages') {
  
        return <Messages user={user} />;
  
      } else if (nav === 'workouts') {
  
        return <Workouts />;
  
      } else if (nav === 'nutrition') {
  
        return <Nutrition />;
  
      } else if (nav === 'users') {
  
        return <Users />;
  
      } else if (nav === 'settings') {
  
        return <Settings />;
  
      } else if (nav === 'data') {
  
        return <Data />;
  
      }
  
      
    }

    return (
      <>
      { userauth ? (
        <div id="app" style={({ height: "100vh", display: 'flex'})} data-bs-theme="dark">
          <Sidebar backgroundColor="#2e2e2e" style={{ height: "100vh", borderColor: '#808080'}}>
          <Menu iconShape="square">
            <MenuItem
              icon={<MenuOutlinedIcon />}
              onClick={() => collapseSidebar()}
              style={{ textAlign: "center", backgroundColor: "#808080" }}
            >
              <img alt="" src={logo_name} style={{ maxWidth: "100%", maxHeight: '100%', display: "block" }} />
            </MenuItem>

            <MenuItem
              icon={<HomeOutlinedIcon />}
              onClick={() => handleMenuItemClick("dashboard")}
              style={{
                backgroundColor: nav === "dashboard" ? "#808080" : "#2e2e2e",
                color: nav === "dashboard" ? "black" : "#ededed"
              }}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              icon={<ChatBubbleOutlineIcon />}
              onClick={() => handleMenuItemClick("messages")}
              style={{
                backgroundColor: nav === "messages" ? "#808080" : "#2e2e2e",
                color: nav === "messages" ? "black" : "#ededed"
              }}
            >
              Messages
            </MenuItem>
            <MenuItem
              icon={<FitnessCenterIcon />}
              onClick={() => handleMenuItemClick("workouts")}
              style={{
                backgroundColor: nav === "workouts" ? "#808080" : "#2e2e2e",
                color: nav === "workouts" ? "black" : "#ededed"
              }}
            >
              Workouts
            </MenuItem>
            <MenuItem
              icon={<MonitorHeartOutlinedIcon />}
              onClick={() => handleMenuItemClick("nutrition")}
              style={{
                backgroundColor: nav === "nutrition" ? "#808080" : "#2e2e2e",
                color: nav === "nutrition" ? "black" : "#ededed"
              }}
            >
              Nutrition
            </MenuItem>
            <MenuItem
              icon={<PeopleIcon />}
              onClick={() => handleMenuItemClick("users")}
              style={{
                backgroundColor: nav === "users" ? "#808080" : "#2e2e2e",
                color: nav === "users" ? "black" : "#ededed"
              }}
            >
              Users
            </MenuItem>
            <MenuItem
              icon={<SettingsIcon />}
              onClick={() => handleMenuItemClick("settings")}
              style={{
                backgroundColor: nav === "settings" ? "#808080" : "#2e2e2e",
                color: nav === "settings" ? "black" : "#ededed"
              }}
            >
              Settings
            </MenuItem>
            <MenuItem
              icon={<FolderOpenIcon />}
              onClick={() => handleMenuItemClick("data")}
              style={{
                backgroundColor: nav === "data" ? "#808080" : "#2e2e2e",
                color: nav === "data" ? "black" : "#ededed"
              }}
            >
              Data
            </MenuItem>
          </Menu>

          <Menu style={{ position: 'fixed', bottom: 0, paddingBottom: 10,  }}>
            <MenuItem icon={<LogoutIcon style={{ color: 'white' }} />} onClick={onLogOutPress}>
              <span style={{ color: 'white' }}>Logout</span>
            </MenuItem>
          </Menu>
          </Sidebar>

          <main className='main-container' style={{ flex: 1, transition: "margin-left 0.2s ease", overflow: 'auto'}}>
            <PageController />
          </main>
        </div>
      ) : (
        <div id="app" style={({ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' })}>
          {showalert && (
                <Alert className="popup-modal" style={{left: 50}} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading style={{fontSize: 20, fontWeight: '400', marginBottom: 0}}>{alerttitle}</Alert.Heading>
                </Alert>
            )}
          <Container fluid className="remove-container-padding" >
           
            <Row className="justify-content-center">
              <Col xs={12} sm={8} md={6} lg={4} style={{ padding: 50, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)' }}>
              <div style={{padding: 50}}>
                <img alt="" src={logo_admin} style={{ maxWidth: "100%", maxHeight: '100%', display: "block" }} />
              </div>
              <Form onSubmit={handleSubmit(onSignInPressed)}>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel label="Email">
                        <Controller
                          name="username" // Name of the input field
                          control={control} // Reference to the form object
                          defaultValue="" // Default value for the input
                          rules={{ required: 'Email is required' }} // Add validation rules if needed
                          render={({ field }) => (
                            <Form.Control required type="text" {...field} />
                          )}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel label="Password">
                        <Controller
                          name="password" // Name of the input field
                          control={control} // Reference to the form object
                          defaultValue="" // Default value for the input
                          rules={{ required: 'Password is required' }} // Add validation rules if needed
                          render={({ field }) => (
                            <Form.Control required type="password" {...field} />
                          )}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col>
                      <Button style={{ marginTop: 0, marginRight: 5 }} type="submit" variant="warning">
                        {loading ? 'Loading...' : 'LOGIN'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      


        </>
    )

  }

  const ContactUs = () => {
    const emailAddress = 'cody@cronusfit.org'; // Replace with the recipient's email address
    const [showalert, setShowAlert] = useState(false);
    const [alerttitle, setAlertTitle] = useState('');
  
    const handleSendEmail = () => {
      const subject = 'Cronusfit - Concerns from the user';
      const body = 'Type your concerns here...';
  
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
      // Open the user's default email client
      window.location.href = mailtoLink;
    };
  
    return (
      <div id="app" style={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0 }}>
        {showalert && (
          <Alert className="popup-modal" style={{ left: 50 }} variant="success" onClose={() => setShowAlert(false)} dismissible>
            <Alert.Heading style={{ fontSize: 20, fontWeight: '400', marginBottom: 0 }}>{alerttitle}</Alert.Heading>
          </Alert>
        )}
        <Container fluid className="remove-container-padding">
          <Row className="justify-content-center mb-3">
            <Col xs={12} sm={8} md={6} lg={4}>
              <h1>Email us your concerns</h1>
            </Col>
          </Row>
          <Row className="justify-content-center mb-3">
            <Col xs={12} sm={8} md={6} lg={4}>
                <Row className="justify-content-center mb-3">
                  <Col>
                    <Button style={{ marginTop: 0, marginRight: 5 }} onClick={handleSendEmail} variant="warning">
                      Launch Email Client
                    </Button>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (

      <Routes>
        <Route path="/" element={<AppContainer />} />
        <Route path="/unsubscribe" element={<UnsubscribeForm />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    
       
  );
}

/*

 <Container fluid className="remove-container-padding" style={{backgroundColor: 'green', display: 'flex', alignItems: 'center' }}>
          <Row className="justify-content-center flex"  style={{backgroundColor: 'blue'}}>
            <Col xs={12} sm={8} md={6} lg={4} >
              <Form onSubmit={handleSubmit(onSignInPressed)}>
                <Row className="mb-3">
                  <Col>
                    <FloatingLabel label="Email">
                      <Controller
                        name="username" // Name of the input field
                        control={control} // Reference to the form object
                        defaultValue="" // Default value for the input
                        rules={{ required: 'Email is required' }} // Add validation rules if needed
                        render={({ field }) => (
                          <Form.Control required type="text" {...field} />
                        )}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <FloatingLabel label="Password">
                      <Controller
                        name="password" // Name of the input field
                        control={control} // Reference to the form object
                        defaultValue="" // Default value for the input
                        rules={{ required: 'Password is required' }} // Add validation rules if needed
                        render={({ field }) => (
                          <Form.Control required type="password" {...field} />
                        )}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col>
                    <Button style={{ marginTop: 0, marginRight: 5 }} type="submit" variant="warning">
                      {loading ? 'Loading...' : 'LOGIN'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

*/



export default App;


