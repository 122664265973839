import { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listUsers, getUser, listMessages  } from '../../graphql/queries';
import Switch from 'react-switch';
import Select from 'react-select';

import { updateUser, createMessages } from '../../graphql/mutations';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';


function Users(props) {

    function UserList() {
      const [users, setUsers] = useState([]);
      const [searchQuery, setSearchQuery] = useState("");
      const [selectedUser, setSelectedUser] = useState(null);
      const [showalert, setShowAlert] = useState(false);
      const [alerttitle, setAlertTitle] = useState('');

      //Editable Fields
      const [editedName, setEditedName] = useState("");
      const [editedCoach, setEditedCoach] = useState("");
      const [editedCoachId, setEditedCoachId] = useState("");
      const [editedCoachYn, setEditedCoachYn] = useState(false);
      const [coachOptions, setCoachOptions] = useState([]); // Define coach options
      const [selectedCoachOption, setSelectedCoachOption] = useState(null); // Define selected coach option

      
      /*
      useEffect(() => {
        fetchUsers();
      }, []);
      */
  
      useEffect(() => {

        fetchUsers();

        if (selectedUser) {
          setEditedName(selectedUser.name);
          setEditedCoach(selectedUser.coach_userid);
          setEditedCoachId(selectedUser.coach_userid);


          if (selectedUser.coach_yn)
            setEditedCoachYn(selectedUser.coach_yn);
          else 
            setEditedCoachYn(false);
  
         
        } else {
          setEditedName("");
          setEditedCoach("");
          setEditedCoachId("");
          setEditedCoachYn(false);
        }
      }, [selectedUser]);
  
      //useEffect(() => {
        //fetchCoaches();
      //}, []);
  
      const fetchUsers = async () => {
        try {
          const response = await API.graphql(graphqlOperation(listUsers));
          const userList = response.data.listUsers.items;
          setUsers(userList);
        } catch (error) {
          console.log("Error fetching users:", error);
        }
      };
  
      const filteredUsers = users.filter(
        (user) =>
          (user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase())) &&
          !user._deleted
      );
  
      const handleSave = async (e) => {
        e.preventDefault();
  
        try {

          console.log(selectedCoachOption)
          const input = {
            id: selectedUser.id,
            name: editedName,
            coach_userid: selectedCoachOption ? selectedCoachOption : null,
            coach_yn: editedCoachYn,
            _version: selectedUser._version,
            updatedAt: new Date().toISOString(),
          };

          console.log(input)
  
          const response = await API.graphql(
            graphqlOperation(updateUser, { input })
          );

          const updatedUser = response.data.updateUser;

          console.log('we made it here')
          //check if there are already messages between these two users. if not create one
          const var_compare_id_1 = selectedUser.id
          const var_compare_id_2 = selectedCoachOption ? selectedCoachOption : null

          console.log(var_compare_id_1, var_compare_id_2)
          if (var_compare_id_2) {

            try {
              const message_query_response = await API.graphql(
                graphqlOperation(listMessages, {
                  filter: {
                    or: [
                      {
                        and: [
                          { sender_userid: { eq: var_compare_id_1 } },
                          { receiver_userid: { eq: var_compare_id_2 } },
                        ],
                      },
                      {
                        and: [
                          { sender_userid: { eq: var_compare_id_1 } },
                          { receiver_userid: { eq: var_compare_id_2 } },
                        ],
                      },
                    ],
                  },
                })
              );

              const messages_array = message_query_response.data.listMessages.items

              const filtered_message_array = messages_array.filter((e) => !e._deleted)

              
              if (filtered_message_array.length === 0) {
              
                  const messageData = {
                    message: "Hello! Welcome to Cronusfit. Here you will handle all of the interactions with your coach.",
                    sender_userid: var_compare_id_2,
                    receiver_userid: var_compare_id_1,
        
                  };
              
                  // Create a new message in the backend
                  await API.graphql(
                    graphqlOperation(createMessages, { input: messageData })
                  ); 

                        
                }

            } catch (e) {
              console.error('Error: ', e)
            }   

          }
        

          setSelectedUser(updatedUser);
          setEditedCoachId(selectedCoachOption ? selectedCoachOption.value : undefined);

  
          // Show the modal
          setShowAlert(true);
          setAlertTitle("User Information Saved! ")
  
          // Hide the modal after 2 seconds
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } catch (error) {
          console.log("Error updating user:", error);
        }
      };
  
      const handleCancel = (e) => {
        e.preventDefault();

        setSelectedUser(null);
      };
  
      
      
      const handleCoachChange = (selectedOption) => {

        console.log(selectedOption)
        setSelectedCoachOption(selectedOption);
      
        // Set the editedCoachId based on the selectedOption
        if (selectedOption) {
          setEditedCoachId(selectedOption.value);
        } else {
          setEditedCoachId("");
        }
      };

      const handleSelectingUser = async (user) => {
        try {
          const response = await API.graphql(
            graphqlOperation(listUsers, {
              filter: {
                coach_yn: { eq: true },
                _deleted: { ne: true}
              },
            })
          );
      
          const coachList = response.data.listUsers.items;
      
          // Create options array from coach data
          const options = coachList.map((coach) => ({
            value: coach.id,
            label: coach.name,
          }));

          console.log(options)
      
          setCoachOptions(options);
          setSelectedUser(user);
      
          const defaultCoachOption = options.find(
            (option) => option.value === user.coach_userid
          );

          console.log(defaultCoachOption)

          if (defaultCoachOption) {
            console.log('this')
            setSelectedCoachOption(defaultCoachOption.value);
          } else {
            console.log('that')
            setSelectedCoachOption(null);
          }
          
        } catch (error) {
          console.log("Error fetching coaches:", error);
        }
      };
          
      return (
        <Container fluid="xxl" className='p-3' style={{height: '100vh', color: 'white'}}>
          {showalert && (
                <Alert className="popup-modal" variant="success" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading style={{fontSize: 20, fontWeight: '400', marginBottom: 0}}>{alerttitle}</Alert.Heading>
                </Alert>
          )}
          <Row className="g-3 mb-3">
            <h1 style={{color: 'white'}}>Users</h1>
          </Row>
          {selectedUser ? (
            <Container className="user-details" >
              <Col lg={6}>
                <Form onSubmit={handleSave}>
                  <Row className="mb-3">
                    <Col>
                        <FloatingLabel
                            label="Name"
                          >
                            <Form.Control required type="text" id="name" value={editedName} onChange={(e) => setEditedName(e.target.value)}/>
                        </FloatingLabel>
                      </Col>
                  </Row>
        
                  <Row className="mb-3">
                    <Col>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="coachynswitch"
                        label="Is this user a Coach?"
                        checked={editedCoachYn}
                        onChange={(e) => setEditedCoachYn(e.target.checked)}
                        style={{color: 'white'}}
                      /> 
                    </Col>
                  </Row>

              {editedCoachYn ? (
                <></>
              ) : (
                <Row className="mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="coach-userid">
                      <Form.Label style={{ color: 'white' }}>Select Coach:</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedCoachOption}
                        onChange={(e) => handleCoachChange(e.target.value)}
                      >
                        <option value="">Select an option...</option> {/* Option to de-select */}
                        {coachOptions.map((coach, index) => (
                          <option key={index} value={coach.value}>
                            {coach.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
              </Row>
              )}
                 

                  <Row>
                    <Col sm={12} lg={6}>
                      <Button style={{marginTop: 0, marginRight: 5}} variant="outline-warning" type="submit" rounded="sm">Save</Button>
                      <Button style={{marginTop: 0}} onClick={handleCancel} variant="outline-warning" type="button">Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Container>
          ) : (
            <>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
              <Col>
                  <Form.Control type="text" placeholder="Search Users" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} data-bs-theme="dark"/>
              </Col>
            </Row>
            <Row>
              <div className="users-grid">
                {filteredUsers
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((user) => (
                    <Card key={user.id}
                          body
                          className="custom-card"
                          onClick={() => handleSelectingUser(user)}>
                        <h4 className="custom-card-title">{user.name}</h4>
                    </Card>
                  ))}
                </div>
              </Row>
            </>
          )}
      
          
        </Container>


      );
    }

      return <UserList />;
    }

export default Users


